import { css } from "@emotion/core";

const grid = css`
  width: 100%;
  padding: 0 var(--external-gutter);
  position: relative;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(var(--number-of-columns), 1fr);
  grid-column-gap: var(--gutter-size);
`;

const fullbleedgrid = css`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns:
    [wrapper-start] calc(var(--external-gutter) - var(--gutter-size))
    [start] repeat(var(--number-of-columns), 1fr) [end] calc(
      var(--external-gutter) - var(--gutter-size)
    )
    [wrapper-end];
  grid-column-gap: var(--gutter-size);
`;

export { grid, fullbleedgrid };
