export default ({ template }, { t, theme }) => {
  const currentUrl =
    typeof window !== "undefined"
      ? window.location.href.replace("?closedplayer", "")
      : "";
  const shareData = {
    film: {
      title: t("curiosity_share_title"),
      theme: theme?.label,
      shareUrl: currentUrl,
      socialLinks: [
        {
          type: "facebook",
          link: `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`,
        },
        {
          type: "twitter",
          link: `https://twitter.com/intent/tweet?url=${currentUrl}&text=${t(
            "curiosity_share_sociallink"
          )}`,
        },
      ],
    },
  };

  return {
    ...shareData[template ? template : "film"],
  };
};
