import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { RichText as PrismicRichText } from "prismic-reactjs";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { mediaquery } from "../../../../styles/theme";
import htmlSerializer from "../../../config/htmlSerializer";
import { decodeString } from "../../../utils";

const clampStyles = ({ clamp, clampMd }) => css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${clamp &&
  css`
    -webkit-line-clamp: ${clamp};
  `}

  ${clampMd &&
  mediaquery.md(css`
    -webkit-line-clamp: ${clampMd};
  `)}
`;

const StyledRichText = styled.div`
  word-break: break-word;
  overflow-wrap: anywhere;
  ${({ clamp, clampMd }) =>
    (clamp || clampMd) && clampStyles({ clamp, clampMd })};
`;

const RichText = ({ data, clamp, clampMd, className }) => {
  return (
    <StyledRichText
      className={["rich-text", className].filter(Boolean).join(" ")}
      clamp={clamp}
      clampMd={clampMd}
    >
      {typeof data === "string" ? (
        decodeString(data)
      ) : (
        <PrismicRichText render={data} htmlSerializer={htmlSerializer} />
      )}
    </StyledRichText>
  );
};

RichText.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  clamp: PropTypes.number,
  clampMd: PropTypes.number,
  className: PropTypes.string,
};
RichText.defaultProps = {
  clamp: null,
  clampMd: null,
};

export default memo(RichText);
