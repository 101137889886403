import React, { useCallback, useContext, useEffect, useMemo } from "react";

import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/core";
import {
  colors,
  mediaquery,
  scaleSpacing,
  zIndexes,
} from "../../../../styles/theme";
import Button from "../../Atoms/Button";

import { NotoBody, OswaldH1, OswaldH2 } from "../../Atoms/Typography";

import { useTranslation } from "react-i18next";
import { InplayerContext } from "../../../contexts/inplayerContext";

const appear = keyframes`
	0% {
		opacity: 0;
	}
	60% {
		opacity: 0;
	}
  100% {
		opacity: 1;
	}
`;

const ContentContainer = styled.div`
  z-index: ${zIndexes.top};
  position: relative;
  padding: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 2 * var(--external-gutter));
  height: 100%;
  padding-top: 10%;
  animation: 1s ease 0s normal forwards 1 ${appear};

  ${mediaquery.md(css`
    width: calc(var(--col) * 12);
    margin-bottom: 0;
    padding-top: 0;
  `)};
`;

const AssetContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.div`
  color: ${colors.white};
  text-align: center;
  ${OswaldH2}
  width: 100%;

  ${mediaquery.md(css`
    ${OswaldH1}
  `)};
`;

const Description = styled.div`
  color: ${colors.white};
  text-align: center;
  ${NotoBody}
  width: 100%;
  padding: 0 var(--gutter-size);
  margin-top: ${scaleSpacing(4)};

  ${mediaquery.md(css`
    padding: 0;
  `)};
`;

const PaywallPlayer = ({ video }) => {
  const {
    openPaywallPlayer,
    addPaywallAsset,
    removePaywallAssets,
    paywallContentInjected,
    isInitialized,
  } = useContext(InplayerContext);

  const { t } = useTranslation();

  const show = useCallback(() => {
    openPaywallPlayer(video);
  }, [video, openPaywallPlayer]);

  useEffect(() => {
    if (isInitialized) {
      removePaywallAssets();
      addPaywallAsset(video?.id);
    }
    addPaywallAsset(video?.id);
  }, [isInitialized, addPaywallAsset, removePaywallAssets, video?.id]);

  const sasVideoData = useMemo(() => {
    const isDev = process.env.GATSBY_ENV !== "production";
    const sasPageId = () => {
      if (video?.disablePreroll) {
        return isDev ? "1484930" : "1485039";
      }
      return isDev ? "1387795" : "1387794";
    };
    return {
      id: `inplayer-${video.id}`,
      language: "FR",
      adData: {
        isValid: true,
        sas_networkid: "3854",
        sas_siteid: "370690",
        sas_pageid: sasPageId(),
        sas_format_overlays: "95103",
        sas_format_linears: "95103",
        sas_target: "",
      },
      configuration: {
        publisher: {
          muteAdsAtStartup: false,
          unmuteOnMouseOver: true,
          visibilityThresholdForUnmute: 0,
          enableMobileClickThroughButton: true,
        },
        labels: {
          lbl_more: {
            FR: t("preroll_read_more"),
          },
        },
        overlays: {
          hideDuringPause: true,
        },
        vpaid: {
          enableSkip: true,
          enableSkipLabel: true,
        },
      },
    };
  }, [t, video?.disablePreroll, video.id]);

  useEffect(() => {
    window.sas.video.clearPlayer(`inplayer-${video.id}`);
    window.sas.video.register(sasVideoData);
  }, [sasVideoData, video]);

  return (
    <>
      <AssetContainer
        id={`inplayer-${video.id}`}
        className="inplayer-paywall-asset"
        style={{ display: paywallContentInjected ? "block" : "none" }}
      />
      {!paywallContentInjected &&
        (video.price !== "freemium" ? (
          <ContentContainer>
            <Title>{t("inplayer_introModal_paid_Title")}</Title>
            <Description>
              {t("inplayer_introModal_paid_Description")}
            </Description>
            <Button
              buttonTheme="primary"
              onClick={show}
              css={css`
                margin-top: ${scaleSpacing(6)};
              `}
            >
              {t("inplayer_introModal_paid_ctaLabel")}
            </Button>
          </ContentContainer>
        ) : (
          <ContentContainer>
            <Title>{t("inplayer_introModal_Title")}</Title>
            <Description>{t("inplayer_introModal_Description")}</Description>
            <Button
              buttonTheme="primary"
              onClick={show}
              css={css`
                margin-top: ${scaleSpacing(6)};
              `}
            >
              {t("inplayer_introModal_ctaLabel")}
            </Button>
          </ContentContainer>
        ))}
    </>
  );
};

PaywallPlayer.propTypes = {
  video: PropTypes.object,
};

export default React.memo(PaywallPlayer);
