import PropTypes from "prop-types";
import { default as React } from "react";
import Link from "../../Atoms/Link";
import {
  connectStateResults,
  Hits,
  Index,
  PoweredBy,
  connectAutoComplete,
} from "react-instantsearch-dom";

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits;
  return hitCount > 0 ? (
    <div className="HitCount">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  ) : null;
});

const PageHit = ({ hit }) => {
  return (
    <div>
      <Link link={{ url: hit.url }}>
        <h4>{hit.title}</h4>
      </Link>
    </div>
  );
};

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Hits className="Hits" hitComponent={PageHit} />
  </Index>
);

const SearchResult = ({ hits, indices, className }) => {
  if (!hits.length) return null;
  return (
    <>
      <span className="separator" />
      <div className={className}>
        {indices.map(index => (
          <HitsInIndex index={index} key={index.name} />
        ))}
        <PoweredBy />
      </div>
    </>
  );
};

PageHit.propTypes = {
  hit: PropTypes.object,
};
HitsInIndex.propTypes = {
  index: PropTypes.object,
};
SearchResult.propTypes = {
  hits: PropTypes.array,
  indices: PropTypes.array,
  className: PropTypes.string,
};

export default connectAutoComplete(SearchResult);
