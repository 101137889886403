import React, { useContext } from "react";
import PropTypes from "prop-types";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";
import StyledSearchBox from "./StyledSearchBox";
import StyledSearchAutocomplete from "./StyledSearchAutocomplete";
import StyledSearchRoot from "./StyledSearchRoot";
import { UIContext } from "../../../contexts/ui";
import { createURL } from "../../../utils/url";

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  {
    _useRequestCache: true,
  }
);

const searchClient = {
  search(requests) {
    const shouldSearch = requests.some(
      ({ params: { query } }) => query.length > 2
    );
    if (shouldSearch) {
      return algoliaClient.search(requests);
    }
    return Promise.resolve({
      results: [{ hits: [] }],
    });
  },
  searchForFacetValues: algoliaClient.searchForFacetValues,
};

const Search = ({ indices }) => {
  const { closeModal } = useContext(UIContext);

  return (
    <StyledSearchRoot>
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        createURL={createURL}
      >
        <StyledSearchBox handleClose={closeModal} isInModal />

        <StyledSearchAutocomplete indices={indices} />
      </InstantSearch>
    </StyledSearchRoot>
  );
};

Search.propTypes = {
  indices: PropTypes.array,
  location: PropTypes.object,
};

export default Search;
