import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Link from "../../Atoms/Link";
import { buttonStyle } from "../../../../styles/buttons";

const StyledButton = styled.button`
  cursor: pointer;
`;

const Button = ({
  link,
  buttonTheme,
  children,
  ariaLabel,
  className,
  type,
  as,
  onClick,
}) => {
  if (link) {
    return (
      <Link link={link} className={className} css={buttonStyle(buttonTheme)}>
        <span>{children}</span>
      </Link>
    );
  } else {
    return (
      <StyledButton
        as={as}
        className={className}
        css={buttonStyle(buttonTheme)}
        aria-label={ariaLabel}
        onClick={onClick}
        type={type}
      >
        <span>{children}</span>
      </StyledButton>
    );
  }
};

Button.propTypes = {
  link: PropTypes.object,
  children: PropTypes.node,
  buttonTheme: PropTypes.string,
  className: PropTypes.string,
  as: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  ariaLabel: PropTypes.string,
  rel: PropTypes.string,
  blurHover: PropTypes.bool,
  type: PropTypes.string,
};

Button.defaultProps = {
  buttonTheme: "default",
  target: "",
  ariaLabel: "",
  rel: "",
  blurHover: false,
  type: "button",
};

export default React.memo(Button);
