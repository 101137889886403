import { css } from "@emotion/core";
import { mediaquery } from "./theme";

const ads = css`
  /* custom */

  /*  Ad server */

  div[id^="sas_"] {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    a {
      width: 100%;
    }
    iframe {
      margin: 0 auto;
    }
    img {
      width: 100%;
      object-fit: contain;
    }

    a + iframe,
    iframe + iframe {
      position: absolute;
    }
  }

  #sas_96773 {
    max-height: 245px;

    ${mediaquery.md(css`
      display: block;
      max-height: 250px;
    `)};
  }

  #sas_96774 {
    aside && {
      a,
      img {
        width: 300px;
        height: 480px;
      }
    }
  }

  #sas_96776 {
    background-color: transparent;
    max-width: 327px;
    img {
      height: auto;
    }
    ${mediaquery.md(css`
      max-width: 300px;
    `)};
  }

  #sas_94952 {
    background-color: transparent;
    max-width: 1440px;
    margin: auto;
    img {
      height: auto;
    }
  }
`;

export default ads;
