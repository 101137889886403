import React, { memo, useEffect } from "react";
import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/core";
import PropTypes from "prop-types";
import {
  breakpoints,
  colors,
  getAdImageFormat,
  rem,
  mediaquery,
} from "../../../../styles/theme";
import Image from "../../Atoms/Image";
import Link from "../../Atoms/Link";
import useWindowSize from "../../../hooks/useWindowSize";

const appear = keyframes`
	0% {
		opacity: 0;
	}
	90% {
		opacity: 0;
	}
  100% {
		opacity: 1;
	}
`;

const BannerWrapper = styled.div`
  /* Force GPU acceleration to fix iOS sticky flicker on scroll */
  transform: translate3d(0, 0, 0);
  justify-content: center;
  align-items: center;
  width: 100%;
  position: ${({ sticky }) => (sticky ? "fixed" : "relative")};
  top: 0;
  z-index: 0;
  overflow: hidden;
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "flex")};

  background-color: ${({ backgroundColor }) =>
    backgroundColor || "transparent"};

  height: ${({ imgHeight }) => (imgHeight ? `${imgHeight}px` : "auto")};

  aside && {
    position: ${({ sticky }) => (sticky ? "sticky" : "relative")};
    margin-top: 0;
    width: ${rem(300)};
    height: ${({ scaleRatio, imgHeight }) =>
      scaleRatio ? `calc(${imgHeight}px * ${scaleRatio})` : `${imgHeight}px`};
  }

  ${mediaquery.md(css`
    margin: auto;
    display: flex;
  `)};

  & ~ main {
    margin-top: ${({ imgHeight }) => (imgHeight ? `${imgHeight}px` : 0)};
  }
`;

const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.lightgrey3};
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: translate3d(0, 0, 0);
  ${mediaquery.md(css`
    display: block;
  `)};
`;

const Banner = styled.div`
  a {
    width: 100%;
  }
  iframe {
    margin: 0 auto;
  }
  img {
    width: 100%;
    object-fit: contain;
  }
`;

const FallbackLink = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ sticky }) => (sticky ? -2 : -1)};
  animation: 4s ease 0s normal forwards 1 ${appear};
`;

const ConditionalLink = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const AdBanner = ({ config }) => {
  const {
    image,
    link,
    smartadserver_nwid,
    smartadserver_site_id,
    smartadserver_format_id,
    smartadserver_pgname,
    smartadserver_page_id,
    smartadserver_format_target,
    smartadserver_enable = true,
    noFallback = false,
    hideOnMobile = false,
    isSticky = false,
    bgColor,
    scaleRatio = 1,
  } = config || {};

  const { width } = useWindowSize();
  const isMobile = width < breakpoints.md;

  useEffect(() => {
    if (
      smartadserver_site_id &&
      smartadserver_page_id &&
      smartadserver_format_id &&
      smartadserver_enable
    ) {
      window.sas.cmd.push(function () {
        window.sas.call("std", {
          siteId: smartadserver_site_id,
          pageId: smartadserver_page_id,
          formatId: smartadserver_format_id,
          target: smartadserver_format_target,
        });
      });
    }
  }, [
    smartadserver_enable,
    smartadserver_format_id,
    smartadserver_format_target,
    smartadserver_page_id,
    smartadserver_site_id,
  ]);

  if (!smartadserver_enable || !smartadserver_page_id) return null;
  const fallbackImageIdFormat = getAdImageFormat(smartadserver_format_id);

  const fallbackImage =
    image &&
    fallbackImageIdFormat &&
    image.thumbnails[
      isMobile ? fallbackImageIdFormat.mobile : fallbackImageIdFormat.desktop
    ];

  const fallbackImageHeight = fallbackImage?.dimensions?.height || "auto";
  const fallbackImageWidth = fallbackImage?.dimensions?.width || "100%";

  return (
    <BannerWrapper
      sticky={isSticky ? 1 : 0}
      scaleRatio={scaleRatio}
      hideOnMobile={hideOnMobile ? 1 : 0}
      imgHeight={fallbackImage && fallbackImageHeight}
      backgroundColor={bgColor ? bgColor : "transparent"}
    >
      <BannerContainer>
        {fallbackImage && !noFallback && (
          <ConditionalLink
            condition={link}
            wrapper={children => (
              <FallbackLink
                link={link}
                sticky={isSticky ? 1 : 0}
                css={{
                  width: fallbackImageWidth * scaleRatio,
                  height: fallbackImageHeight * scaleRatio,
                }}
              >
                {children}
              </FallbackLink>
            )}
          >
            <Image
              source={fallbackImage}
              sizes={`${fallbackImageWidth}px`}
              style={{
                width: fallbackImageWidth * scaleRatio,
                height: fallbackImageHeight * scaleRatio,
              }}
              isFixed
            />
          </ConditionalLink>
        )}
        <Banner id={`sas_${smartadserver_format_id}`} />
        <noscript>
          <a
            href={`https://www14.smartadserver.com/ac?
          jump=1&nwid=${smartadserver_nwid}&siteid=${smartadserver_site_id}&pgname=${smartadserver_pgname}&fmtid=${smartadserver_format_id}&visit=m&tmstp=[timestamp]&out=nonrich`}
            target="_blank"
            rel="noopener"
          >
            <img
              src={`https://www14.smartadserver.com/ac?out=nonrich&nwid=${smartadserver_nwid}&siteid=${smartadserver_site_id}&pgname=${smartadserver_pgname}&fmtid=${smartadserver_format_id}&visit=m&tmstp=[timestamp]`}
              border="0"
              alt=""
            />
          </a>
        </noscript>
      </BannerContainer>
    </BannerWrapper>
  );
};

AdBanner.propTypes = {
  config: PropTypes.object,
  smartadserver_enable: PropTypes.bool,
};
AdBanner.defaultProps = {
  smartadserver_enable: false,
};

export default memo(AdBanner);
