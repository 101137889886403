import React, { useCallback, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { navigate } from "@reach/router";

import { UserContext } from "./userContext";
import { UIContext } from "./ui";
import scriptLoader from "../utils/scriptLoader";

export const InplayerContext = React.createContext();

const InPlayerProvider = ({ children }) => {
  const [paywall, setPaywall] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [userData, setUserData] = useState({});
  const [paywallContentInjected, setPaywallContentInjected] = useState(false);
  const { openModal, closeModal } = useContext(UIContext);
  const { isAuthenticated, syncAuthWithToken } = useContext(UserContext);
  const [loaded, setLoaded] = useState(false);

  // Init Inplayer

  useEffect(() => {
    if (!window.InplayerPaywall) {
      scriptLoader(() => {
        setLoaded(true);
      }, "https://assets.inplayer.com/paywall/v3.21.0/paywall.min.js");
    } else if (!paywall) {
      setLoaded(true);
    } else {
      setLoaded(false);
    }
  }, [loaded, paywall]);

  useEffect(() => {
    if (loaded) {
      const newPaywall = new window.InplayerPaywall(
        "3c9d2ed1-32b0-400b-8269-707c4b23412c",
        [],
        {
          brandingId: 2288,
          hideLogo: true,
          hideUserMenu: true,
          hideFooterLinks: true,
          language: "fr",
        }
      );
      newPaywall.setLanguage("fr");
      setPaywall(newPaywall);
    }
  }, [loaded, paywall]);

  // Handle paywall events

  useEffect(() => {
    if (paywall) {
      paywall.on("authenticated", (e, data) => {
        syncAuthWithToken();
        setUserData(data);
      });

      paywall.on("init", () => {
        setIsInitialized(true);
      });

      paywall.on("logout", () => {
        syncAuthWithToken();
        setPaywallContentInjected(false);
      });

      paywall.on("inject", () => {
        setPaywallContentInjected(true);
      });

      // Close from user click
      paywall.on("close", () => {
        closeModal(); // close all app modals
        window.location.reload();
      });

      // Close from internal events
      paywall.on("any", function (e) {
        if (e.type === "close_modal") {
          document.documentElement.classList.remove("newsFormFieldsOnly");
          document.documentElement.classList.remove("infosFormFieldsOnly");
        }
      });
    }
  }, [closeModal, paywall, syncAuthWithToken]);

  // Methods

  const openUserModal = useCallback(() => {
    openModal({
      modalId: "userMenu",
    });
  }, [openModal]);

  const showUserMenu = useCallback(() => {
    if (paywall) {
      if (isAuthenticated) {
        openUserModal();
      } else {
        paywall.showPaywall();
        paywall.on("authenticated", () => {
          openUserModal();
        });
      }
    }
  }, [paywall, isAuthenticated, openUserModal]);

  const openUserMenuPanel = useCallback(
    panel => {
      if (paywall && panel) {
        closeModal();
        // FIXME: potential memory leak
        setTimeout(() => {
          switch (panel) {
            case "login":
              return paywall.setLoginScreen();
            case "register":
              return paywall.setRegisterScreen();
            case "newsletter":
              return paywall.showMyAccount();
            case "purshases":
              return paywall.showMyAccount(); // to be adapted when able to reach directly purtchases screen
            case "cardDetails":
              return paywall.showCreditCardDetails();
            case "changePassword":
              return paywall.showChangePassword();
            case "logout":
              return paywall.logoutUser();
            case "account":
              // default:
              return paywall.showMyAccount();
          }
        }, 400);
      }
    },
    [closeModal, paywall]
  );

  const addPaywallAsset = useCallback(
    id => {
      paywall?.addAssets([
        {
          id,
          options: {
            noPreview: true,
          },
        },
      ]);
    },
    [paywall]
  );

  const removePaywallAssets = useCallback(() => {
    setPaywallContentInjected(false);
  }, []);

  const openPaywallPlayer = useCallback(
    video => {
      if (video.id) {
        navigate(`?openplayer=${video.id}&price=${video.price}`);
        paywall.showPaywall({
          asset: {
            assetId: video.id,
          },
        });
      }
    },
    [paywall]
  );

  return (
    <InplayerContext.Provider
      value={{
        paywall,
        userData,
        isInitialized,
        isAuthenticated,
        paywallContentInjected,
        addPaywallAsset,
        removePaywallAssets,
        openPaywallPlayer,
        showUserMenu,
        openUserMenuPanel,
      }}
    >
      {children}
    </InplayerContext.Provider>
  );
};

InPlayerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InPlayerProvider;
