import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { withPrefix } from "gatsby";

import { iconSize } from "../../../../styles/theme";

const Container = styled.span`
  position: relative;

  ${({ display }) => css`
    display: ${display || "inline-flex"}};
  `};

  ${({ size, height }) => css`
    width: ${size || iconSize.regular}px;
    height: ${height || size || iconSize.regular}px;
  `};

  svg {
    width: 100%;
    height: 100%;
  }
`;

/* pass the name & fill props (that we will specify in our
other components) to Icon to pick the right icon */
const Svg = ({ name, label, fill, size, height, display, ...rest }) => {
  return (
    <Container {...rest} display={display} size={size} height={height}>
      {name && (
        <svg fill={fill} aria-labelledby={label && "label"} name={name}>
          <use href={withPrefix(`icons/${name}.svg#${name}`)} />
          {label && <title id="label">{label}</title>}
        </svg>
      )}
    </Container>
  );
};

/* set the propTypes so we can catch bugs with typechecking */
Svg.propTypes = {
  name: PropTypes.string.isRequired,
  fill: PropTypes.string,
  size: PropTypes.number,
  height: PropTypes.number,
  display: PropTypes.string,
  label: PropTypes.string,
};

export default memo(Svg);
