import styled from "@emotion/styled";
import { css } from "@emotion/core";

import {
  mediaquery,
  scaleSpacing,
  zIndexes,
  colors,
  hexRgba,
} from "../../../../styles/theme";

export default styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 20vh 0;

  .separator {
    display: block;
    height: 1px;
    margin-top: -1px;
    width: calc(100% - 2 * ${scaleSpacing(6)});
    background-color: ${hexRgba(colors.mediumgrey2, 0.2)};
    z-index: ${zIndexes.middle};
    padding: 0 ${scaleSpacing(12)};
    ${mediaquery.md(css`
      width: calc(100% - 2 * ${scaleSpacing(12)});
    `)};
  }
`;
