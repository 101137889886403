import { css } from "@emotion/core";
import { rem, mediaquery } from "../../../../styles/theme";

/* Fonts */
export const Oswald = css`
  font-family: "Oswald";
`;

export const Noto = css`
  font-family: "Noto Sans JP";
`;

export const Slabo = css`
  font-family: "Slabo 27px";
`;

/* Oswald Typography */
export const OswaldH1 = css`
  ${Oswald};
  font-weight: 300;
  font-size: ${rem(48)};
  line-height: ${rem(58)};
`;

export const OswaldH2 = css`
  ${Oswald};
  font-weight: 300;
  font-size: ${rem(40)};
  line-height: ${rem(52)};
`;

export const OswaldH3 = css`
  ${Oswald};
  font-weight: 300;
  font-size: ${rem(36)};
  line-height: ${rem(42)};
  letter-spacing: -0.001em;
`;

export const OswaldH4 = css`
  ${Oswald};
  font-weight: 300;
  font-size: ${rem(32)};
  line-height: ${rem(42)};
  letter-spacing: -0.001em;
`;

export const OswaldH5 = css`
  ${Oswald};
  font-weight: 300;
  font-size: ${rem(28)};
  line-height: ${rem(36)};
  letter-spacing: 0.002em;
`;

export const OswaldH6 = css`
  ${Oswald};
  font-weight: 300;
  font-size: ${rem(24)};
  line-height: ${rem(32)};
  letter-spacing: 0.002em;
`;

export const OswaldOverline1 = css`
  ${Oswald};
  font-weight: 500;
  font-size: ${rem(24)};
  line-height: ${rem(32)};
  letter-spacing: 0.06em;
  text-transform: uppercase;
`;

export const OswaldOverline2 = css`
  ${Oswald};
  font-weight: 500;
  font-size: ${rem(20)};
  line-height: ${rem(28)};
  letter-spacing: 0.06em;
  text-transform: uppercase;
`;

export const OswaldOverline3 = css`
  ${Oswald};
  font-weight: 500;
  font-size: ${rem(18)};
  line-height: ${rem(24)};
  letter-spacing: 0.06em;
  text-transform: uppercase;
`;

export const OswaldOverline4 = css`
  ${Oswald};
  font-weight: 500;
  font-size: ${rem(14)};
  line-height: ${rem(16)};
  letter-spacing: 0.06em;
  text-transform: uppercase;
`;

export const OswaldTitle = css`
  ${OswaldH5} letter-spacing: 0.002em;
  ${mediaquery.md(css`
    ${OswaldH4};
    letter-spacing: -0.001em;
  `)};
`;

/* Noto Typography */

export const NotoEditoLarge = css`
  ${Noto};
  font-weight: 400;
  font-size: ${rem(20)};
  line-height: ${rem(36)};
`;

export const NotoEditoBody = css`
  ${Noto};
  font-weight: 400;
  font-size: ${rem(16)};
  line-height: ${rem(32)};
  letter-spacing: 0.1px;
`;

export const NotoLarge = css`
  ${Noto};
  font-weight: 400;
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  letter-spacing: 0.001em;
`;

export const NotoBody = css`
  ${Noto};
  font-weight: 400;
  font-size: ${rem(16)};
  line-height: ${rem(28)};
  letter-spacing: 0.1px;
`;

export const NotoBodySmall = css`
  ${Noto};
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  letter-spacing: 0.1px;
`;

export const NotoSmall = css`
  ${Noto};
  font-weight: 500;
  font-size: ${rem(12)};
  line-height: ${rem(16)};
`;

export const NotoLink = css`
  ${Noto};
  font-weight: 500;
  font-size: ${rem(16)};
  line-height: ${rem(16)};
`;

export const NotoLinkSmall = css`
  ${Noto};
  font-weight: 500;
  font-size: ${rem(14)};
  line-height: ${rem(16)};

  &:hover {
    text-decoration-line: underline;
  }
`;

export const NotoLabel = css`
  ${Noto};
  font-weight: 500;
  font-size: ${rem(10)};
  line-height: ${rem(14)};
  text-transform: none;

  &:hover {
    text-decoration-line: none;
  }
`;

/* Slabo Typography */

export const SlaboSubtitle = css`
  ${Slabo};
  font-size: ${rem(20)};
  line-height: ${rem(34)};
`;

export const SlaboQuote = css`
  ${Slabo};
  font-size: ${rem(28)};
  line-height: ${rem(40)};
`;
