import styled from "@emotion/styled";
import { css } from "@emotion/core";
import SearchAutocomplete from "./SearchAutocomplete";
import { OswaldH3, OswaldH6 } from "../../Atoms/Typography";
import { mediaquery, scaleSpacing } from "../../../../styles/theme";

const Popover = css`
  width: calc(100% - var(--external-gutter) * 2);
  max-height: 60vh;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  padding-top: ${scaleSpacing(14)};
  background: white;
  border-radius: ${scaleSpacing(10)};
  overflow: hidden;

  ${mediaquery.md(css`
    max-height: 42vh;
    margin: 0;
    width: calc(var(--col) * 14);
    padding-top: ${scaleSpacing(20)};
  `)};
`;
export default styled(SearchAutocomplete)`
  ${Popover}
  .HitCount {
    display: none;
    position: absolute;
    top: ${scaleSpacing(20)};
    right: ${scaleSpacing(6)};
    padding: 0 ${scaleSpacing(6)};

    ${mediaquery.md(css`
      top: ${scaleSpacing(26)};
    `)};
  }

  .Hits {
    max-height: calc(60vh - ${scaleSpacing(14)});
    overflow-y: scroll;

    ul {
      list-style: none;
      margin: 0 ${scaleSpacing(6)};
      display: block;
    }

    ${mediaquery.md(css`
      max-height: calc(42vh - ${scaleSpacing(20)});
      ul {
        margin: 0 ${scaleSpacing(12)};
      }
    `)};

    li.ais-Hits-item {
      margin: ${scaleSpacing(6)} ${scaleSpacing(12)} ${scaleSpacing(6)} 0;
      a {
        display: block;
        padding: 0;
        ${OswaldH6};
        text-decoration: none;
        ${mediaquery.md(css`
          ${OswaldH3};
        `)};
      }
      &:last-of-type {
        margin-bottom: ${scaleSpacing(12)};
      }
    }
  }
  .ais-PoweredBy {
    display: none;
  }
`;
