import React from "react";
import { Elements } from "prismic-richtext";

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
const htmlSerializer = function (type, element) {
  if (type === Elements.preformatted) {
    return React.createElement("div", {
      className: "preformatted",
      dangerouslySetInnerHTML: { __html: element.text },
    });
  } else {
    return null;
  }
};

export default htmlSerializer;
