import { sponsorAdapter } from "../../Molecules/Sponsors/adapter";
import { slugify } from "../../../../styles/theme";

export default (
  {
    id,
    uid,
    url,
    image,
    title,
    director,
    description,
    excerpt,
    free,
    year,
    duration,
    category,
    genre,
    country,
    logo,
    video_id,
    video_player,
    video_embed,
    video_price,
    smartadserver_disable_preroll,
    sponsors,
    sponsors_title,
    sponsors_description,
  },
  { t }
) => {
  const sponsorData = sponsors
    ?.map(sponsor => ({ ...sponsorAdapter(sponsor) }))
    .filter(sponsor => !!sponsor.logo);

  const hasSponsor = sponsorData?.length > 0;
  return {
    id,
    uid,
    picture: image?.thumbnails?.sixteenByNine || image,
    title,
    subtitle: director,
    description: excerpt || description?.raw,
    free,
    ctaLabel: category
      ? t(`curiosity_figure_ctalabel_${slugify(category)}`)
      : t("curiosity_figure_ctalabel"),
    ctaLink: {
      url,
    },
    logo: logo?.thumbnails?.partner || logo,
    duration,
    category,
    genre,
    country,
    year,
    video: {
      title: title,
      id: video_id,
      player: video_player,
      embed: video_embed,
      price: video_price,
      disablePreroll: smartadserver_disable_preroll,
    },
    hasSponsor,
    sponsorsTitle: hasSponsor && sponsors_title,
    sponsorsDescription:
      (hasSponsor &&
        !!sponsors_description?.raw[0] &&
        sponsors_description?.raw) ||
      t("sponsors_short_text"),
    sponsors: sponsorData,
  };
};

export const articleAdapter = data => {
  const {
    uid,
    url,
    type,
    title,
    format,
    formatted_publication_date,
    author,
    image,
    description,
    excerpt,
    is_external_article,
    link_label,
    link,
    themes,
    reading_time,
    website,
    hasTagInfos,
  } = data;

  return {
    uid,
    // TODO: set default to "troiscouleurs" until current articles "website" is set,
    website: website || "troiscouleurs",
    picture: image?.thumbnails?.fourByThree || image,
    description: description?.raw || description?.text,
    title,
    date: formatted_publication_date,
    author,
    excerpt,
    ctaLabel: link_label,
    format,
    // TODO: remove is_external_article when current articles "website" updated to "external",
    isExternal: is_external_article || website === "external",
    ctaLink: link?.url ? link : { url },
    linkStyle: "tertiary",
    isArticle: type === "article",
    hasArticleIcon:
      // TODO: remove is_external_article when current articles "website" updated to "external",
      (!is_external_article || website !== "external") &&
      format &&
      format !== "Author" &&
      format !== "Portrait",
    hasTagInfos,
    tagInfos:
      type === "article"
        ? [
            ...(themes?.map(theme => theme?.name && theme.name) || []),
            ...[format],
            ...[`${reading_time} min`],
          ].filter(Boolean)
        : [],
  };
};
