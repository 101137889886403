import React from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/core";
import { ThemeProvider } from "emotion-theming";

import { themes } from "../../styles/theme";
import globalStyles from "../../styles/globalStyles";
import "../../styles/reset.css";
import "swiper/swiper-bundle.min.css";

// storybook won't build correctly in production if website is null
const website = process.env.GATSBY_WEBSITE || "curiosity";

const GlobalContainer = ({ children }) => (
  <ThemeProvider theme={themes[website]}>
    <Global styles={globalStyles} />
    {children}
  </ThemeProvider>
);

GlobalContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalContainer;
