import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { connectMenu } from "react-instantsearch-dom";
import { colors, scaleSpacing } from "../../../../styles/theme";

import IconSvg from "../../Atoms/Svg";
import { NotoBody } from "../../Atoms/Typography";

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  margin: 0 ${scaleSpacing(2)} ${scaleSpacing(2)};
  justify-content: center;
  flex: 1;
`;

const StyledSelect = styled.select`
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  &:focus {
    border-color: ${colors.darkgrey2};
  }
  width: 100%;
  position: relative;
  height: ${scaleSpacing(12)};
  padding: 0 ${scaleSpacing(10)} 0 ${scaleSpacing(6)};
  margin: 0;
  border-radius: ${scaleSpacing(7)};
  border: none;
  outline: none;
  ${NotoBody};
  background: ${colors.darkgrey4};
  color: ${colors.white};
`;

const Svg = styled(IconSvg)`
  color: ${colors.mediumgrey1};
  position: absolute;
  right: ${scaleSpacing(5)};
  top: 0;
  bottom: 0;
  margin: auto;
  pointer-events: none;
`;

const arrowStyle = css`
  transform: rotate3d(0, 0, 1, 90deg);
`;

const RefinementSelect = ({
  items,
  currentRefinement,
  refine,
  label,
  isBool,
  labelTrue,
  labelFalse,
}) => {
  const [resultsCount, setResultsCount] = useState(null);

  useEffect(() => {
    let numOr0 = n => (isNaN(n) ? 0 : n);
    const total =
      items.length > 1
        ? items.reduce(
            (a, b) => numOr0(a.count ? a.count : a) + numOr0(b.count)
          )
        : items[0]
        ? items[0].count
        : 0;

    setResultsCount(total);
  }, [items, setResultsCount]);

  const getBoolLabel = label => (label === "true" ? labelTrue : labelFalse);
  return items.length > 0 ? (
    <SelectWrapper>
      <StyledSelect
        value={currentRefinement || ""}
        onChange={event => refine(event.currentTarget.value)}
      >
        <option value="">
          {label} {`(${resultsCount})`}
        </option>
        {items.map(item => (
          <option
            key={item.label}
            value={item.isRefined ? currentRefinement : item.value}
          >
            {isBool ? getBoolLabel(item.label) : item.label} ({item.count})
          </option>
        ))}
      </StyledSelect>
      <Svg
        css={arrowStyle}
        name="arrow"
        size={6}
        height={10}
        fill={colors.lightgrey}
      />
    </SelectWrapper>
  ) : (
    <></>
  );
};

RefinementSelect.propTypes = {
  items: PropTypes.array.isRequired,
  currentRefinement: PropTypes.array,
  createURL: PropTypes.func,
  label: PropTypes.string,
  refine: PropTypes.func,
  isBool: PropTypes.bool,
  labelTrue: PropTypes.string,
  labelFalse: PropTypes.string,
};

const SearchRefinementSelect = connectMenu(RefinementSelect);

export default SearchRefinementSelect;
