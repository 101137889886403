import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  connectStats,
  Index,
  connectCurrentRefinements,
  Configure,
} from "react-instantsearch-dom";
import { useTheme } from "emotion-theming";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { CustomHits } from "../CardGrid";
import { SearchRefinementList } from "../Search";
import Button from "../../Atoms/Button";
import IconSvg from "../../Atoms/Svg";
import {
  mediaquery,
  scaleSpacing,
  breakpoints,
  colors,
} from "../../../../styles/theme";
import useWindowSize from "../../../hooks/useWindowSize";
import { NotoBody } from "../../Atoms/Typography";
import FigureList, { featuredArticleFigureListAdapter } from "../FigureList";

const MenuSelectWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  margin-bottom: ${scaleSpacing(13)};
  padding: 0 calc(var(--external-gutter));
  flex-direction: row;
  flex-direction: column;

  ${mediaquery.md(css`
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    margin-bottom: ${scaleSpacing(16)};
    padding: 0 calc(var(--external-gutter));
    flex-direction: row;
  `)};

  display: ${({ show }) => (show ? "flex" : "none")};
`;
const Svg = styled(IconSvg)`
  position: absolute;
  right: ${scaleSpacing(5)};
  top: 0;
  bottom: 0;
  margin: auto;
  pointer-events: none;
`;

const FilterButton = styled(Button)`
  width: calc(100% - 2 * var(--external-gutter));
  margin: 0 var(--external-gutter) ${scaleSpacing(6)};
  color: ${({ color }) => color};
  text-align: left;
  &:before {
    box-shadow: none;
    background-color: ${({ bgColor }) => bgColor};
  }

  > span {
    margin-right: ${scaleSpacing(6)};
    width: 100%;
  }
`;

const HitsInIndex = ({ index, filters, config, ads, featuredArticle }) => {
  const { width } = useWindowSize();
  const isMobile = width < breakpoints.md;
  const [showFilters, setShowFilters] = useState(isMobile);

  const { t } = useTranslation();
  const theme = useTheme();

  const {
    category_maxColumns,
    category_hitsPerPage,
    category_grid_fixedPushEnable,
    category_grid_fixedPushMobilePosition,
    category_grid_fixedPushRowPosition,
    category_grid_fixedPushColumnPosition,
  } = config || null;

  const withAds =
    category_grid_fixedPushEnable && config.category_grid_fixedPushEnable;
  const hitsPerPage = withAds ? category_hitsPerPage - 1 : category_hitsPerPage;

  return (
    <Index indexName={index.name}>
      <Configure hitsPerPage={hitsPerPage} filters={filters} />
      {isMobile && (
        <FilterButton
          buttonTheme="secondary"
          isMobile={isMobile}
          onClick={() => setShowFilters(f => !f)}
          bgColor={theme.filterBackgroundColor}
          color={theme.filterColor}
        >
          {t("search_ShowFiltersButton_ctaLabel")}{" "}
          <Svg name="filter" size={20} height={20} fill={theme.filterColor} />
        </FilterButton>
      )}
      <MenuSelectWrapper show={!isMobile || showFilters} isMobile={isMobile}>
        <SearchRefinementList
          attribute="universes.name"
          label={t("search_attribute_name_universe")}
        />
        <SearchRefinementList
          attribute="themes.name"
          label={t("search_attribute_name_theme")}
        />
        <SearchRefinementList
          attribute="format"
          label={t("search_attribute_name_format")}
        />
        <SearchRefinementList
          attribute="directors.name"
          label={t("search_attribute_name_director")}
          isSearchable
        />
        <SearchRefinementList
          attribute="actors.name"
          label={t("search_attribute_name_actor")}
          isSearchable
        />
        <SearchRefinementList
          attribute="country"
          label={t("search_attribute_name_country")}
        />
        <SearchRefinementList
          attribute="genre"
          label={t("search_attribute_name_genre")}
        />
        <SearchRefinementList
          attribute="category"
          label={t("search_attribute_name_type")}
        />
        <SearchRefinementList
          attribute="free"
          label={t("search_attribute_name_price")}
          labelTrue="Gratuit"
          labelFalse="Payant"
          isBool
        />
      </MenuSelectWrapper>
      <Stats />
      <FeaturedArticle featuredArticle={featuredArticle} />
      <CustomHits
        maxColumns={category_maxColumns}
        fixedPushEnable={category_grid_fixedPushEnable}
        fixedPushMobilePosition={category_grid_fixedPushMobilePosition}
        fixedPushRowPosition={parseInt(category_grid_fixedPushRowPosition)}
        fixedPushColumnPosition={parseInt(
          category_grid_fixedPushColumnPosition
        )}
        ads={ads}
      />
    </Index>
  );
};

HitsInIndex.propTypes = {
  index: PropTypes.object,
  filters: PropTypes.string,
  config: PropTypes.object.isRequired,
  ads: PropTypes.object,
  featuredArticle: PropTypes.object,
};

const SearchCategory = ({ indices, filters, config, ads, featuredArticle }) => {
  return (
    <>
      {indices?.map(index => (
        <HitsInIndex
          index={index}
          key={index.name}
          config={config}
          ads={ads}
          filters={filters}
          featuredArticle={featuredArticle}
        />
      ))}
    </>
  );
};

SearchCategory.propTypes = {
  indices: PropTypes.array,
  filters: PropTypes.string,
  config: PropTypes.object.isRequired,
  ads: PropTypes.object,
  featuredArticle: PropTypes.object,
};

export default SearchCategory;

function ResultCount({ nbHits }) {
  const { t } = useTranslation();
  const label = t(
    nbHits === 1 ? "search_result_label" : "search_results_label"
  );
  return (
    <ResultCountContainer>
      <span>{nbHits}</span>
      &nbsp;
      <span>{label}</span>
    </ResultCountContainer>
  );
}

ResultCount.propTypes = {
  nbHits: PropTypes.number.isRequired,
};

const Stats = connectStats(ResultCount);

const ResultCountContainer = styled.div`
  ${NotoBody}
  color: ${colors.mediumgrey1};
  display: flex;
  justify-content: center;
  margin-bottom: ${scaleSpacing(13)};
  ${mediaquery.md(css`
    display: none;
  `)}
`;

const CurrentRefinements = ({ items, featuredArticle }) => {
  return (
    <>
      {featuredArticle?.document && items.length === 0 && (
        <FigureList
          {...featuredArticleFigureListAdapter({
            featuredArticle,
          })}
        />
      )}
    </>
  );
};

CurrentRefinements.propTypes = {
  items: PropTypes.array,
  featuredArticle: PropTypes.object,
};

const FeaturedArticle = connectCurrentRefinements(CurrentRefinements);
