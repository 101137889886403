import styled from "@emotion/styled";
import { css } from "@emotion/core";
import CustomSearchBox from "./SearchBox";
import { OswaldH3, OswaldH5 } from "../../Atoms/Typography";
import { colors, mediaquery, scaleSpacing } from "../../../../styles/theme";

export default styled(CustomSearchBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: calc(100% - var(--external-gutter) * 2);
  border-radius: ${scaleSpacing(7)};
  z-index: 2;
  position: relative;

  ${mediaquery.md(css`
    width: calc(var(--col) * 14);
    margin-top: ${scaleSpacing(4.5)};
  `)};

  .SearchIcons {
    position: absolute;
    right: ${scaleSpacing(4)};
    ${mediaquery.md(css`
      right: ${scaleSpacing(10)};
    `)};
  }

  .SearchInput {
    width: 100%;
    height: ${scaleSpacing(14)};
    padding: 0 ${scaleSpacing(6)} ${scaleSpacing(1)};
    border-radius: ${scaleSpacing(10)};
    border: none;
    outline: none;
    ${OswaldH5};
    color: ${colors.white};
    background: white;
    cursor: text;

    ${mediaquery.md(css`
      ${OswaldH3};
      margin: 0;
      height: ${scaleSpacing(20)};
      padding: 0 ${scaleSpacing(12)} ${scaleSpacing(1)};
      width: calc(var(--col) * 14);
    `)};

    &::placeholder {
      color: ${colors.white};
    }

    &:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-text-fill-color: ${colors.white} !important;
    }

    color: black;
    ::placeholder {
      color: ${colors.mediumgrey1};
    }
  }
`;
