import { useStaticQuery, graphql } from "gatsby";
import { logos } from "../../../../styles/theme";

export default ({ template, theme }, { t }) => {
  const newsletterList = useStaticQuery(graphql`
    query NewsletterModalQuery {
      prismicNewsletterModal {
        data {
          title
          subtitle
          newsletters {
            audience
            description
          }
        }
      }
      ...Configuration
    }
  `);

  const { title, subtitle, newsletters } =
    newsletterList?.prismicNewsletterModal?.data || {};

  const website = process.env.GATSBY_WEBSITE;

  const siteConfig = newsletterList.allPrismicSiteConfiguration?.edges?.find(
    ({ node }) => node.data.site === website
  ).node.data;

  const newsletterTemplatesData = {
    institut: {
      theme: theme,
      emojis: "",
      title: t("institut_newsletter_title", { theme: "Institut" }),
      subtitle: t("institut_newsletter_subtitle"),
      legalsMention: t("common_newsletter_legalsmention", {
        theme: "Institut",
      }),
      legalsMentionUrl: t("common_newsletter_legalsmention_url"),
      ctaLink: "",
      ctaLabel: t("common_newsletter_manage_label"),
    },
    curiosity: {
      theme: theme,
      emojis: "",
      title: t("curiosity_newsletter_title", { theme: "Curiosity" }),
      subtitle: t("curiosity_newsletter_subtitle"),
      legalsMention: t("common_newsletter_legalsmention", {
        theme: "Curiosity",
      }),
      legalsMentionUrl: t("common_newsletter_legalsmention_url"),
      ctaLink: "",
      ctaLabel: t("common_newsletter_manage_label"),
    },
    troiscouleurs: {
      theme: theme,
      emojis: "",
      title: t("troiscouleurs_newsletter_title", { theme: "troiscouleurs" }),
      subtitle: t("troiscouleurs_newsletter_subtitle"),
      legalsMention: t("common_newsletter_legalsmention", {
        theme: "troiscouleurs",
      }),
      legalsMentionUrl: t("common_newsletter_legalsmention_url"),
      ctaLink: "",
      ctaLabel: t("common_newsletter_manage_label"),
    },
    mini: {
      theme: theme,
      emojis: "",
      title: t("troiscouleurs_newsletter_title", { theme }),
      subtitle: t("troiscouleurs_newsletter_subtitle"),
      legalsMention: t("common_newsletter_legalsmention", {
        theme,
      }),
      legalsMentionUrl: t("common_newsletter_legalsmention_url"),
      ctaLink: "",
      ctaLabel: t("common_newsletter_manage_label"),
      mini: true,
    },
    allNewsletterModal: {
      theme: theme,
      title,
      subtitle,
      sections: newsletters?.map(section => {
        return {
          logo: logos[section.audience],
          id: section.audience,
          description: section.description,
        };
      }),
      legalsMention: t("common_newsletter_legalsmention", {
        theme: "Curiosity",
      }),
      legalsMentionUrl: t("common_newsletter_legalsmention_url"),
    },
    newsletterModal: {
      theme: theme,
      emojis: "",
      title: t("common_newsletter_title", { theme: "Curiosity" }),
      subtitle: t("curiosity_newsletter_subtitle"),
      legalsMention: t("common_newsletter_legalsmention", {
        theme: "Curiosity",
      }),
      legalsMentionUrl: t("common_newsletter_legalsmention_url"),
    },
    newsletterModalPlayer: {
      theme: theme,
      emojis: "",
      title: t("inpopinvideo_newsletter_title"),
      subtitle: t("inpopinvideo_newsletter_subtitle"),
      legalsMention: t("common_newsletter_legalsmention", {
        theme: "Curiosity",
      }),
      legalsMentionUrl: t("common_newsletter_legalsmention_url"),
    },
    default: {
      theme: theme,
      emojis: "",
      title: t("common_newsletter_title", { theme: "Institut" }),
      subtitle: t("institut_newsletter_subtitle"),
      legalsMention: t("common_newsletter_legalsmention", {
        theme: "Institut",
      }),
      legalsMentionUrl: t("common_newsletter_legalsmention_url"),
    },
  };

  return {
    ...newsletterTemplatesData[template ? template : "default"],
    enableManageLink: siteConfig.show_newsletter_icon,
  };
};
