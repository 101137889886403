import React, { useRef } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import { useTheme } from "emotion-theming";
import styled from "@emotion/styled";
import { useSpring } from "react-spring";
import CardGridItem, { adapter as cardGridItemAdapter } from "../CardGridItem";
import Figure, { articleAdapter } from "../../Molecules/Figure";
import {
  mediaquery,
  scaleSpacing,
  colors,
  zIndexes,
} from "../../../../styles/theme";
import { connectInfiniteHits } from "react-instantsearch-dom";
import { useTranslation } from "react-i18next";
import Button from "../../Atoms/Button";
import IconSvg from "../../Atoms/Svg";
import AdBanner from "../../Atoms/AdBanner";

// Wrapper

const Wrapper = styled.section`
  position: relative;
  margin: 0 var(--external-gutter);
  z-index: ${zIndexes.middle};
  background-color: ${({ bgColor }) => bgColor};
`;

// List

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${scaleSpacing(10)};
  margin-bottom: ${scaleSpacing(10)};

  ${mediaquery.md(css`
    gap: ${scaleSpacing(20)};
    margin-bottom: ${scaleSpacing(20)};
  `)};

  ${({ maxColumns }) =>
    maxColumns > 1 &&
    css`
      ${mediaquery.md(css`
        gap: ${scaleSpacing(10)};
        grid-template-columns: repeat(${maxColumns - 1}, 1fr);
      `)}

      ${mediaquery.l(css`
        grid-template-columns: repeat(${maxColumns}, 1fr);
      `)}
    `}
`;

// FixedItem

const FixedItem = styled.div`
  height: auto;

  ${mediaquery.md(css`
    height: 0;
    padding-top: 100%;
  `)}

  ${({ col, row, fixedPushMobilePosition }) =>
    col &&
    row &&
    fixedPushMobilePosition &&
    css`
      grid-column: 0 / 1;
      grid-row: ${fixedPushMobilePosition} / ${fixedPushMobilePosition + 1};
      ${mediaquery.md(css`
        height: 100%;
        padding-top: 0;
        grid-column: ${col - 1} / ${col};
        grid-row: ${row + 1} / ${row + 2};
      `)}

      ${mediaquery.l(css`
        grid-column: ${col} / ${col + 1};
        grid-row: ${row} / ${row + 1};
      `)}
    `}
`;

// Load More

const ButtonMore = styled(Button)`
  width: 100%;
  margin: ${scaleSpacing(10)} 0;
  color: ${({ color }) => color};
  &:before {
    box-shadow: inset 0 0 0 1px ${({ color }) => color};
  }

  ${mediaquery.sm(css`
    margin: ${scaleSpacing(14)} 0;
  `)}
`;

const SvgArrow = styled(IconSvg)`
  transform: rotate(-90deg);
  margin-right: ${scaleSpacing(4)};
`;

const Hits = ({
  hits,
  hasMore,
  refineNext,
  maxColumns,
  fixedPushEnable,
  fixedPushColumnPosition,
  fixedPushRowPosition,
  fixedPushMobilePosition,
  ads,
}) => {
  const containerRef = useRef(null);

  const { t } = useTranslation();
  const theme = useTheme();

  const [, setScrollTop] = useSpring(() => ({
    immediate: false,
    reset: true,
    y: 0,
    onChange: ({ value }) => {
      window.scroll(0, value.y);
    },
  }));

  const scrollDown = () => {
    containerRef?.current &&
      setScrollTop({
        from: { y: window.scrollY },
        to: {
          y: 0,
        },
      });
  };

  return (
    <Wrapper bgColor={theme?.backgroundColorLight} ref={containerRef}>
      {hits.length > 0 && (
        <List maxColumns={maxColumns}>
          {hits.map(hit => {
            if (!hit) return;
            return hit.type === "article" ? (
              <Figure
                {...articleAdapter({
                  ...hit,
                  label:
                    hit.type !== "film" &&
                    t(`search_category_label_${hit.type}`),
                })}
                key={hit.objectID}
                columns={maxColumns}
                hasTagInfos={true}
              />
            ) : (
              <CardGridItem
                {...cardGridItemAdapter({
                  ...hit,
                  label:
                    hit.type !== "film" &&
                    t(`search_category_label_${hit.type}`),
                })}
                key={hit.objectID}
              />
            );
          })}

          {fixedPushEnable && hits?.length > 6 && (
            <FixedItem
              row={fixedPushRowPosition}
              col={fixedPushColumnPosition}
              fixedPushMobilePosition={fixedPushMobilePosition}
            >
              <AdBanner config={{ ...ads, smartadserver_format_id: 96774 }} />
            </FixedItem>
          )}
        </List>
      )}
      {hits.length > 0 && hasMore && (
        <ButtonMore
          type="button"
          as="button"
          buttonTheme="secondary"
          onClick={refineNext}
          bgColor={theme.filterBackgroundColor}
          color={theme.filterColor}
        >
          {t("search_load_more")}
        </ButtonMore>
      )}

      {hits.length > 8 && !hasMore && (
        <ButtonMore
          type="button"
          as="button"
          buttonTheme="secondary"
          onClick={scrollDown}
        >
          <SvgArrow
            name="arrow"
            size={18}
            height={18}
            fill={colors.lightgrey}
          />
        </ButtonMore>
      )}
    </Wrapper>
  );
};

Hits.propTypes = {
  hits: PropTypes.array.isRequired,
  as: PropTypes.string,
  bgColor: PropTypes.string,
  hasMore: PropTypes.bool,
  refineNext: PropTypes.func,
  maxColumns: PropTypes.number,
  fixedPushEnable: PropTypes.bool,
  fixedPushColumnPosition: PropTypes.number,
  fixedPushRowPosition: PropTypes.number,
  fixedPushMobilePosition: PropTypes.number,
  ads: PropTypes.object,
};
Hits.defaultProps = {
  bgColor: colors.darkgrey2,
  maxColumns: 1,
  fixedPushEnable: false,
  fixedPushMobilePosition: 6,
};

const CustomHits = connectInfiniteHits(Hits);

export default CustomHits;
