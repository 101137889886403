import { useStaticQuery, graphql } from "gatsby";

export default () => {
  const modalSuccessData = useStaticQuery(graphql`
    query NewsletterSuccessQuery {
      ...AllSuccessModals
    }
  `);

  const website = process.env.GATSBY_WEBSITE;

  const data = modalSuccessData?.allPrismicNewsletterSuccessModal?.edges?.find(
    ({ node }) => node.uid === website
  ).node.data;
  const { title, description, sociallinks } = data || {};

  return {
    title,
    description,
    socialLinks: sociallinks,
  };
};
