import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useTranslation } from "react-i18next";
import { useTheme } from "emotion-theming";
import React, { useState, useEffect } from "react";
import { connectMenu } from "react-instantsearch-dom";
import { colors, scaleSpacing, rem, zIndexes } from "../../../../styles/theme";

import { NotoLarge } from "../../Atoms/Typography";
const MenuListItem = styled.ul`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: ${scaleSpacing(-4)};
  margin-bottom: ${scaleSpacing(14)};
  border-bottom: 1px solid ${({ bgColor }) => bgColor};
`;

const MenuItem = styled.div`
  position: relative;
  margin-left: ${scaleSpacing(14)};
  padding-bottom: ${scaleSpacing(6)};
`;

const MenuItemLink = styled.a`
  ${NotoLarge}
  color: ${({ color }) => color};
  position: relative;
  text-decoration: none;
  font-weight: ${({ selected }) => (selected ? "500" : "300")};

  ${({ selected, siteColor }) =>
    selected &&
    siteColor &&
    css`
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 5px;
        left: 0;
        bottom: ${rem(-29)};
        border-radius: 8px;
        background-color: ${siteColor};
        z-index: ${zIndexes.top};
      }
    `}
`;

const Count = styled.span`
  color: ${colors.mediumgrey1};
`;

const Menu = ({ items, refine, createURL }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [hasReset, setHasReset] = useState(true);
  const resultsCount = items.map(i => i?.count).reduce((a, b) => a + b, 0);

  return (
    <MenuListItem bgColor={theme.filterBackgroundColor}>
      <MenuItem key="all">
        <MenuItemLink
          href={createURL()}
          selected={hasReset}
          onClick={e => {
            e.preventDefault();
            setHasReset(true);
            refine("");
          }}
          bgColor={theme.filterBackgroundColor}
          color={theme.filterColor}
          siteColor={theme.primary}
        >
          {t(`search_category_label_all`)} <Count>({resultsCount})</Count>
        </MenuItemLink>
      </MenuItem>

      {items?.length > 1 &&
        items.map(item => (
          <MenuItem key={item.value}>
            <MenuItemLink
              href={createURL(item.value)}
              selected={item.isRefined}
              onClick={e => {
                e.preventDefault();
                setHasReset(false);
                refine(item.value);
              }}
              bgColor={theme.filterBackgroundColor}
              color={theme.filterColor}
              siteColor={theme.primary}
            >
              {t(`search_category_label_${item.label}`)}{" "}
              <Count>({item.count})</Count>
            </MenuItemLink>
          </MenuItem>
        ))}
    </MenuListItem>
  );
};
Menu.propTypes = {
  items: PropTypes.array.isRequired,
  createURL: PropTypes.func,
  refine: PropTypes.func,
};

const CustomMenu = connectMenu(Menu);

export default CustomMenu;
