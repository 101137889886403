import React, { useContext, useCallback } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import IconSvg from "../../Atoms/Svg";
import Button from "../../Atoms/Button";

import { colors, scaleSpacing, mediaquery } from "../../../../styles/theme";
import { NotoBody } from "../../Atoms/Typography";

import { InplayerContext } from "../../../contexts/inplayerContext";
import { useTranslation } from "react-i18next";

const sectionWidth = css`
  margin: 0 var(--external-gutter);

  ${mediaquery.sm(css`
    margin: 0 auto;
    width: calc(var(--col) * 12 - var(--gutter-size));
  `)}

  ${mediaquery.md(css`
    width: calc(var(--col) * 8 - var(--gutter-size));
  `)}

  ${mediaquery.xl(css`
    width: calc(var(--col) * 7 - var(--gutter-size));
  `)}

  ${mediaquery.xxl(css`
    width: calc(var(--col) * 6 - var(--gutter-size));
  `)}
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: ${scaleSpacing(14)};
`;
const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${sectionWidth}
`;
const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  flex: 1;
`;

const Title = styled.span`
  text-transform: initial !important;
  color: ${colors.black};
`;

const MenuLink = styled.li`
  display: flex;
  justify-content: space-between;
  ${NotoBody};
  color: ${colors.black};
  padding: 0 0 ${scaleSpacing(6)};
  cursor: pointer;
  &:not(:first-of-type) {
    padding: ${scaleSpacing(6)} 0;
    border-top: 1px solid ${colors.lightgrey2};
  }
`;
const MenuButton = styled(Button)`
  ${NotoBody}
  margin: ${scaleSpacing(4)} auto ${scaleSpacing(10)} 0;

  ${mediaquery.md(css`
    margin-top: ${scaleSpacing(16)};
  `)};
`;
const Header = styled.header`
  .inplayer-paywall && {
    margin: ${scaleSpacing(8)} 0;
  }
`;

const UserMenu = () => {
  const { t } = useTranslation();
  const { openUserMenuPanel } = useContext(InplayerContext);

  const handleOpenPanel = useCallback(
    panel => {
      if (panel === "newsletter") {
        document.documentElement.classList.add("newsFormFieldsOnly");
      } else if (panel === "account") {
        document.documentElement.classList.add("infosFormFieldsOnly");
      }
      openUserMenuPanel(panel);
    },
    [openUserMenuPanel]
  );

  return (
    <MenuWrapper>
      <div id="brandheader"></div>
      <Header>
        <div className="headline">
          <Title>{t("user_menu_title")}</Title>
        </div>
      </Header>
      <MenuContainer>
        <Menu>
          <MenuLink
            buttonTheme="menuArrow"
            onClick={() => handleOpenPanel("newsletter")}
          >
            <Title>{t("user_menu_newsletter")}</Title>
            <IconSvg
              size={10}
              height={18}
              name="arrow"
              fill={colors.darkgrey2}
            />
          </MenuLink>
          <MenuLink
            buttonTheme="menuArrow"
            onClick={() => handleOpenPanel("account")}
          >
            <Title>{t("user_menu_informations")}</Title>
            <IconSvg
              size={10}
              height={18}
              name="arrow"
              fill={colors.darkgrey2}
            />
          </MenuLink>
          <MenuLink
            buttonTheme="menuArrow"
            className="inplayer-paywall-purchases"
          >
            <Title>{t("user_menu_purshases")}</Title>
            <IconSvg
              size={10}
              height={18}
              name="arrow"
              fill={colors.darkgrey2}
            />
          </MenuLink>
          <MenuLink
            buttonTheme="menuArrow"
            onClick={() => handleOpenPanel("changePassword")}
          >
            <Title>{t("user_menu_passwordUpdate")}</Title>
            <IconSvg
              size={10}
              height={18}
              name="arrow"
              fill={colors.darkgrey2}
            />
          </MenuLink>
        </Menu>
        <MenuButton
          buttonTheme="secondaryDark"
          onClick={() => handleOpenPanel("logout")}
          ariaLabel="logout"
        >
          <Title>{t("user_menu_logout")}</Title>
        </MenuButton>
      </MenuContainer>
    </MenuWrapper>
  );
};

export default React.memo(UserMenu);
