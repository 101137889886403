import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { navigate } from "@reach/router";
import { useTranslation } from "react-i18next";
import { useTheme } from "emotion-theming";

import Modal from "src/components/Molecules/Modal";
import InPlayerProvider from "../contexts/inplayerContext";
import GlobalContainer from "./GlobalContainer";
import Footer from "./Molecules/Footer";
import {
  NewsletterModal,
  adapter as newsletterAdapter,
  NewsletterModalSuccess,
  newsletterModalSuccessAdapter,
} from "./Molecules/Newsletter";

import Share, { adapter as shareAdapter } from "./Molecules/Share";
import PaywallPlayer from "./Molecules/PaywallPlayer";
import BurgerMenu from "./Organisms/BurgerMenu";
import UserMenu from "./Organisms/UserMenu";
import SEO from "./seo/seo";
import Search from "./Molecules/Search";
import { UIContext } from "../contexts/ui";
import AdBanner from "../components/Atoms/AdBanner";

const searchIndices = [
  {
    name: `${process.env.GATSBY_WEBSITE}_INDEX`,
  },
];

const Layout = ({
  children,
  seoData,
  ads,
  location,
  handleFocusInput,
  isSearch,
}) => {
  // handle Modal events
  const videoRef = useRef();
  const [modalsState, setModalsState] = useState(false);
  const openModal = useCallback(({ modalId, modalParams }) => {
    setModalsState({
      ...modalParams,
      [modalId]: true,
    });
  }, []);

  const closeModal = useCallback(
    params => {
      const { modalId, modalParams } = params || {};
      modalId
        ? setModalsState({
            ...modalParams,
            [modalId]: false,
          })
        : setModalsState({
            ...modalParams,
            overlayVideo: false,
            userMenu: false,
            inPlayer: false,
            newsletter: false,
            newsletterSuccess: false,
            share: false,
            search: false,
            embedVideo: null,
          });
      navigate(location?.pathname);
    },
    [location]
  );

  useEffect(() => {
    const queryString =
      typeof window !== "undefined" ? window.location.search : "";
    const urlParams = new URLSearchParams(queryString);
    const videoId = urlParams && urlParams.get("openplayer");
    const videoPrice = urlParams && urlParams.get("price");
    const enablePreroll = urlParams && urlParams.get("preroll");

    if (videoId) {
      openModal({
        modalId: "inPlayer",
        modalParams: {
          video: { id: videoId, price: videoPrice, preroll: enablePreroll },
        },
      });
    }
  }, [openModal]);

  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    const queryString =
      typeof window !== "undefined" ? window.location.search : "";
    const urlParams = new URLSearchParams(queryString);
    const autoOpen = urlParams && urlParams.has("openNewsletter");
    if (autoOpen) {
      openModal({
        modalId: "newsletter",
        modalParams: { template: "allNewsletterModal" },
      });
    }
  }, [openModal]);

  const sasVideoData = disablePreroll => {
    const isDev = process.env.GATSBY_ENV !== "production";
    const sasPageId = () => {
      if (disablePreroll) {
        return isDev ? "1484930" : "1485039";
      }
      return isDev ? "1387795" : "1387794";
    };
    return {
      id: "video-container",
      language: "FR",
      adData: {
        isValid: true,
        sas_networkid: "3854",
        sas_siteid: "370690",
        sas_pageid: sasPageId(),
        sas_format_overlays: "95103",
        sas_format_linears: "95103",
        sas_target: "",
      },
      configuration: {
        publisher: {
          muteAdsAtStartup: false,
          unmuteOnMouseOver: true,
          visibilityThresholdForUnmute: 0,
          enableMobileClickThroughButton: true,
        },
        labels: {
          lbl_more: {
            FR: t("preroll_read_more"),
          },
        },
        overlays: {
          hideDuringPause: true,
        },
        vpaid: {
          enableSkip: true,
          enableSkipLabel: true,
        },
      },
    };
  };

  return (
    <Root>
      {seoData && <SEO {...seoData} />}
      <UIContext.Provider value={{ modalsState, openModal, closeModal }}>
        <InPlayerProvider data={modalsState}>
          {!ads?.smartadserver_disable_masthead && (
            <AdBanner
              config={{
                ...ads,
                smartadserver_format_id: 96773,
                isSticky: true,
                bgColor: "black",
              }}
            />
          )}
          <GlobalContainer>
            <BurgerMenu
              isSearch={isSearch}
              handleFocusInput={handleFocusInput}
            />
            <main>{children}</main>
            <Footer />
          </GlobalContainer>
          <div id="modalsRoot">
            {/* Inplayer Video Modal */}
            <Modal
              isOpen={!!modalsState.inPlayer}
              onClose={() => {
                closeModal({ modalId: "inPlayer", modalParams: modalsState });
              }}
              isFullScreen
              isDark
            >
              <PaywallPlayer {...modalsState} />
            </Modal>
            {/* Internal Video Modal */}
            <Modal
              isOpen={!!modalsState.overlayVideo}
              onClose={() => {
                closeModal({
                  modalId: "overlayVideo",
                  modalParams: modalsState,
                });
              }}
              isFullScreen
              isDark
            >
              <div className="iframe-container">
                <iframe
                  ref={videoRef}
                  src={`https://player.vimeo.com/video/${modalsState?.video?.id}?autoplay=1&playsinline=0&color=ef0000&byline=0&portrait=0&api=1`}
                  width="480"
                  height="360"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                  allow="autoplay"
                />
              </div>
            </Modal>
            {/* Embed Video Modal */}
            <Modal
              isOpen={!!modalsState.embedVideo}
              onClose={() => {
                closeModal({
                  modalId: "embedVideo",
                  modalParams: modalsState,
                });
              }}
              isFullScreen
              isDark
            >
              <div
                className="iframe-container"
                dangerouslySetInnerHTML={{
                  __html: `<iframe
                  srcdoc='
                  <html>
                    <head>

                    <style type="text/css">
                      div.flowplayer {position:absolute;height:100%;background-color:black;background-size:contain;}
                      #video-container {position:absolute;height:100%;width: 100%;}
                      body{margin:0;}
                    </style>

                      <script type="text/javascript">
                      var loadScript = function loadScript(src) {
                        return new Promise(function (resolve, reject) {
                          var script = document.createElement("script");
                          script.type = "text/javascript";
                          script.onload = resolve;
                          script.onerror = reject;
                          script.src = src;
                          script.async = true;
                          document.head.append(script);
                        });
                      };
                      loadScript("https://ced.sascdn.com/tag/3854/smart.js")
                      .then(() => loadScript("https://r.sascdn.com/video/config.js?nwid=3854"))
                      .then(() => loadScript("https://r.sascdn.com/video/controller.js?nwid=3854"))
                      .then(() => {
                        sas.video.register(${JSON.stringify(
                          sasVideoData(modalsState?.video?.disablePreroll)
                        )})
                      })
                      .catch(() => console.error("Something went wrong."))
                      </script>
                    </head>
                    <body>
                      <div id="video-container">
                        ${modalsState?.video?.embed}
                      </div>
                    </body>
                  </html>'
                  />`,
                }}
              />
            </Modal>
            {/* Newsletter Modal */}
            <Modal
              isOpen={!!modalsState.newsletter}
              isScrollable
              onClose={() => {
                closeModal({ modalId: "newsletter", modalParams: modalsState });
              }}
            >
              <NewsletterModal
                {...newsletterAdapter(
                  {
                    template: modalsState.template,
                  },
                  { t }
                )}
                inPopin
              />
            </Modal>
            {/* Newsletter Success Modal */}
            <Modal
              isOpen={!!modalsState.newsletterSuccess}
              onClose={() => {
                closeModal({
                  modalId: "newsletterSuccess",
                  modalParams: modalsState,
                });
              }}
            >
              <NewsletterModalSuccess
                {...newsletterModalSuccessAdapter(
                  {
                    template: modalsState.template,
                  },
                  { t }
                )}
              />
            </Modal>
            {/* Share Modal */}
            <Modal
              isOpen={!!modalsState.share}
              onClose={() => {
                closeModal({ modalId: "share", modalParams: modalsState });
              }}
            >
              <Share
                {...shareAdapter(
                  {
                    template: modalsState.template,
                  },
                  { t, theme }
                )}
              />
            </Modal>
            {/* Search Modal */}
            <Modal
              isOpen={!!modalsState.search}
              onClose={() => {
                closeModal({ modalId: "search", modalParams: modalsState });
              }}
              isSearch
            >
              <Search indices={searchIndices} location={location} />
            </Modal>
            {/* User menu Modal */}
            <Modal
              isOpen={!!modalsState.userMenu}
              onClose={() => {
                closeModal({ modalId: "userMenu", modalParams: modalsState });
              }}
              isFullScreen
              isPanel
              isDark
            >
              <UserMenu {...modalsState} />
            </Modal>
          </div>
        </InPlayerProvider>
      </UIContext.Provider>
    </Root>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoData: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  handleFocusInput: PropTypes.func,
  isSearch: PropTypes.bool,
  ads: PropTypes.object,
};
Layout.defaultProps = {
  handleFocusInput: () => {},
  theme: "default",
};

export default Layout;

// This is needed because otherwise emotion will duplicate style tags in the DOM
const Root = styled.div``;
