import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  colors,
  mediaquery,
  rem,
  scaleSpacing,
  zIndexes,
  getArticleIcon,
} from "../../../../styles/theme";
import Button from "../../Atoms/Button";
import Image from "../../Atoms/Image";
import RichText from "../../Atoms/RichText";
import Link from "../../Atoms/Link";
import {
  NotoSmall,
  OswaldH4,
  OswaldH5,
  OswaldH6,
} from "../../Atoms/Typography";
import ArticleIcon from "../../Organisms/Article/ArticleIcon";

const CardWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  position: relative;
  /* Fix safari radius overflow issue */
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`;

const CardContainer = styled.div`
  position: relative;
  ${({ animate }) =>
    animate &&
    css`
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          .gatsby-image-wrapper {
            transition: transform 300ms ease-out;
            transform: scale3d(1.1, 1.1, 1.1);
            &:after {
              opacity: 0;
            }
          }
        }
      }
    `}

  .gatsby-image-wrapper {
    height: 0;
    padding-top: calc(100% * 4 / 3);

    ${({ animate }) =>
      animate &&
      css`
        transition: transform 600ms ease-out;
      `}

    &:after {
      content: "";
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(
        to top,
        #000000 0%,
        rgba(0, 0, 0, 0.3) 70%,
        rgba(0, 0, 0, 0.6) 100%
      );
      opacity: 0.6;
      transition: opacity 300ms ease-out;
    }
  }
`;

const ImageLink = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Title = styled.div`
  position: absolute;
  bottom: ${scaleSpacing(6)};
  left: ${scaleSpacing(6)};
  max-width: 65%;
  ${OswaldH4};
  color: ${colors.white};
`;

const Subtitle = styled.div`
  ${OswaldH6};

  ${mediaquery.md(css`
    ${OswaldH5};
  `)};
`;

const PlayButtonContainer = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  cursor: pointer;
`;

const PlayButton = styled(Button)`
  position: absolute;
  right: ${scaleSpacing(6)};
  bottom: ${scaleSpacing(6)};
  margin: auto;
  z-index: ${zIndexes.top};
  justify-content: center;
  pointer-events: none;

  span {
    width: ${rem(8)};
  }
  .soundIcon {
    display: flex;
    width: auto;
  }

  ${mediaquery.md(css`
    span {
      width: ${rem(14)};
    }
  `)};
`;

const Label = styled.div`
  ${NotoSmall}
  position: absolute;
  left: ${scaleSpacing(6)};
  top: ${scaleSpacing(6)};
  display: flex;
  flex-direction: row;
  padding: ${scaleSpacing(2)} ${scaleSpacing(4)};
  white-space: nowrap;
  position: absolute;
  color: ${colors.white};
  background: ${colors.curiosity};
  border-radius: ${rem(90)};
  z-index: ${zIndexes.middle};
`;

const CardGridItem = ({
  title,
  free,
  label,
  subtitle,
  ctaLink,
  picture,
  category,
  type,
  animate,
}) => {
  const { t } = useTranslation();

  return (
    <CardWrapper>
      {picture && (
        <CardContainer animate={animate}>
          {(picture.fluid || picture.thumbnails) && (
            <Image source={picture} sizes="60vw" mobileSizes="100vw" />
          )}

          <ImageLink link={ctaLink} linkTheme="area">
            {label && <Label>{label}</Label>}
            {getArticleIcon(category) && (
              <PlayButtonContainer>
                <PlayButton buttonTheme="iconAlpha" ariaLabel="play">
                  <ArticleIcon format={category} />
                </PlayButton>
              </PlayButtonContainer>
            )}

            {title && (
              <Title>
                {!!free && (
                  <Button
                    css={css`
                      border-radius: ${rem(15)};
                      padding: ${rem(3)} ${rem(8)} ${rem(5)};
                      margin-bottom: ${rem(16)};
                      line-height: ${rem(12)};
                      text-transform: none;
                      width: ${rem(60)};
                    `}
                    buttonTheme="tagRed"
                  >
                    {t("curiosity_herowithcarousel_free")}
                  </Button>
                )}
                <RichText data={title} clamp={3} />
                {subtitle && (
                  <Subtitle>
                    <RichText
                      data={`${
                        type === "film" ? t("curiosity_director_prefix") : ""
                      } ${subtitle}`}
                      clamp={2}
                    />
                  </Subtitle>
                )}
              </Title>
            )}
          </ImageLink>
        </CardContainer>
      )}
    </CardWrapper>
  );
};

CardGridItem.propTypes = {
  title: PropTypes.string.isRequired,
  free: PropTypes.bool,
  label: PropTypes.string,
  subtitlePrefix: PropTypes.string,
  typeLabel: PropTypes.string,
  subtitle: PropTypes.string,
  ctaLink: PropTypes.object,
  picture: PropTypes.object.isRequired,
  category: PropTypes.string,
  type: PropTypes.string,
  animate: PropTypes.bool,
};

export default React.memo(CardGridItem);
