import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import {
  colors,
  mediaquery,
  rem,
  scaleSpacing,
} from "../../../../styles/theme";
import Svg from "../../Atoms/Svg";
import Link from "../../Atoms/Link";
import { NotoLink, NotoBody, OswaldH3, OswaldH4 } from "../../Atoms/Typography";

const Container = styled.div`
  height: 100%;
  background: ${colors.white};
  position: relative;
  display: flex;
  padding: 0;
  text-align: center;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  ${mediaquery.md(css`
    height: 100%;
    padding: ${scaleSpacing(14)};
  `)};
`;

const Title = styled.h2`
  ${OswaldH4};
  color: ${colors.black};
  margin-bottom: ${scaleSpacing(4)};

  ${mediaquery.sm(css`
    ${OswaldH3};
  `)};
`;

const Description = styled.p`
  ${NotoBody};
  color: ${colors.black};
`;

const SocialMedia = styled.div`
  height: ${rem(58)};
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.white};
  margin-bottom: ${scaleSpacing(4)};

  ${mediaquery.md(css`
    margin-bottom: 0;
  `)};
`;

const StyledImageLink = styled(Link)`
  ${NotoLink};
  color: ${colors.black};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
  margin-top: ${scaleSpacing(12)};
  margin-right: ${scaleSpacing(8)};
  text-transform: capitalize;
  &:last-of-type {
    margin-right: 0;
  }

  ${mediaquery.md(css`
    margin-top: ${scaleSpacing(12)};
    margin-right: ${scaleSpacing(12)};
  `)};
`;

const NewsletterModal = ({ title, description, socialLinks }) => {
  return (
    <Container>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}

      {!!socialLinks && (
        <SocialMedia>
          {socialLinks.map(
            ({ type, link }) =>
              type && (
                <StyledImageLink
                  link={link}
                  key={type}
                  target="_blank"
                  rel="noopener"
                >
                  <Svg name={type} size={32} height={32} fill={colors.black} />
                </StyledImageLink>
              )
          )}
        </SocialMedia>
      )}
    </Container>
  );
};

NewsletterModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  socialLinks: PropTypes.array,
};

export default React.memo(NewsletterModal);
