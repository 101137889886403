import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";

const s1 = keyframes`
    0% {
      transform: scaleY(0.304);
    }
    16%, 17% {
      transform: scaleY(0.05);
    }
    21%, 34% {
      transform: scaleY(0.9);
    }
    50%, 49% {
      transform: scaleY(0.05);
    }
    54%, 67% {
      transform: scaleY(0.4);
    }
    83%, 82% {
      transform: scaleY(0.05);
    }
    87%, 100% {
      transform: scaleY(0.3);
    }
`;

const s2 = keyframes`
    0% {
      transform: scaleY(1);
    }
    17%, 20% {
      transform: scaleY(0.05);
    }
    25%, 34% {
      transform: scaleY(0.3);
    }
    50%, 53% {
      transform: scaleY(0.05);
    }
    58%, 67% {
      transform: scaleY(0.8);
    }
    83%, 86% {
      transform: scaleY(0.05);
    }
    91%, 100% {
      transform: scaleY(1);
    }

`;

const s3 = keyframes`
    0% {
      transform: scaleY(0.8);
    }
    17%, 24% {
      transform: scaleY(0.05);
    }
    29%, 34% {
      transform: scaleY(0.6);
    }
    50%, 57% {
      transform: scaleY(0.05);
    }
    62%, 67% {
      transform: scaleY(0.9);
    }
    83%, 90% {
      transform: scaleY(0.05);
    }
    95%, 100% {
      transform: scaleY(0.8);
    }
`;

const s4 = keyframes`
    0% {
      transform: scaleY(0.3);
    }
    17% , 28% {
      transform: scaleY(0.05);
    }
    33%, 34% {
      transform: scaleY(0.9);
    }
    50%, 61% {
      transform: scaleY(0.05);
    }
    66%, 67% {
      transform: scaleY(0.35);
    }
    83%, 94% {
      transform: scaleY(0.05);
    }
    99%, 100% {
      transform: scaleY(0.3);
    }
`;

const StyledSoundIcon = styled.span`
  display: flex !important;
  align-items: center;
  position: relative;

  & span:nth-of-type(1),
  & span:nth-of-type(2),
  & span:nth-of-type(3),
  & span:nth-of-type(4) {
    width: 2px;
    margin-right: 3px;
    height: 20px;
    border-radius: 30px;
    background: #ddd;
    transform-origin: 50% 50%;

    ${({ active }) =>
      !active &&
      `
      animation-play-state: paused !important;
    `}
    transition: height 0.3s;
    background: ${({ color }) => color};
  }

  & span:nth-of-type(1) {
    animation: ${s1} 4.14s infinite;
  }

  & span:nth-of-type(2) {
    animation: ${s2} 4.14s infinite;
  }

  & span:nth-of-type(3) {
    animation: ${s3} 4.14s infinite;
  }

  & span:nth-of-type(4) {
    animation: ${s4} 4.14s infinite;
  }

  & span:last-child {
    margin-right: 0;
  }
`;

const SoundIcon = ({ active, color }) => {
  return (
    <StyledSoundIcon active={active} color={color} className="soundIcon">
      <span />
      <span />
      <span />
      <span />
    </StyledSoundIcon>
  );
};

SoundIcon.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
};

export default memo(SoundIcon);
