import { css } from "@emotion/core";

// Sizes

const BASE_SPACING = 4;
const BASEFONTSIZE = 16;

const rem = value => {
  // Value must be in pixels then return new value in rem
  return `${(value * (10 / BASEFONTSIZE)) / 10}rem`;
};

const scaleSpacing = (value, unitless = false) => {
  if (unitless) {
    return BASE_SPACING * value;
  }
  return `${BASE_SPACING * value}px`;
};

// Colors

const colors = {
  white: "#FFFFFF",
  black: "#000000",
  lightgrey: "#F7F7F7",
  lightgrey2: "#F0F0F0",
  lightgrey3: "#EFEFEF",
  lightgrey4: "#EDEDED",
  mediumgrey1: "#767676",
  mediumgrey2: "#606060",
  mediumgrey3: "#ADADAD",
  darkgrey1: "#3B3B3B",
  darkgrey2: "#191919",
  darkgrey3: "#3D3D3D",
  darkgrey4: "#2C2C2C",
  institut: "#A21FFF",
  troiscouleurs: "#FF56A3",
  curiosity: "#1547FF",
  cinema: "#E20D13",
  yellow: "#DEAD00",
  facebook: "#3B5998",
  twitter: "#00ACEE",
  error: "#A12027",
  success: "#34A853",
};

// Icon sizes

const iconSize = {
  regular: 24,
};

// Breakpoints

const breakpoints = {
  xs: 350,
  sm: 720,
  md: 1024,
  l: 1320,
  xl: 1536,
  xxl: 1750,
};

// Media-queries

const mediaquery = Object.keys(breakpoints).reduce(
  (acc, label) => ({
    ...acc,
    [label]: (...rules) => css`
      @media (min-width: ${breakpoints[label]}px) {
        ${rules};
      }
    `,
  }),
  {}
);

// Indexes

const zIndexes = {
  minus: -1,
  bottom: 1,
  middle: 2,
  top: 3,
  header: 4,
  modal: 5,
};

// HexToRgb

const hexToRgb = hex => {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const hexRgba = (hex, alpha) => {
  const color = hexToRgb(hex);
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
};

// CSS variables

const cssVariables = css`
  :root {
    -webkit-font-smoothing: antialiased;
    --header-height: 64px;
    --number-of-columns: 4;
    --external-gutter: 24px;
    --gutter-size: 8px;
    --col-width: calc(
      (
          100vw - (var(--external-gutter) * 2) -
            (var(--gutter-size) * (var(--number-of-columns) - 1))
        ) / var(--number-of-columns)
    );
    --col: calc(var(--col-width) + var(--gutter-size));
  }
  ${mediaquery.sm(css`
    :root {
      --number-of-columns: 24;
      --external-gutter: 56px;
      --gutter-size: 16px;
    }
  `)};
  ${mediaquery.md(css`
    :root {
      --header-height: 76px;
    }
  `)};
`;

const getSearchType = univers => {
  switch (univers) {
    case "univers-episodes":
      return "episode";
    case "univers-films-podcasts":
      return "film";
    case "univers-collections":
      return "collection";
    case "univers-guests":
      return "guest";
    default:
      break;
  }
};

const getArticleIcon = format => {
  switch (format) {
    case "Article":
      return "article";

    case "Gallery":
      return "gallery";

    case "Video":
      return "play";

    case "Podcast":
      return "podcast";

    case "Author":
      return "author";

    default:
      return "";
  }
};

const getAdImageFormat = id => {
  let sizes;
  switch (id) {
    case 96773:
      sizes = {
        mobile: "paveStandardMobile",
        desktop: "mastheadStandard",
      };
      break;

    case 96774:
      sizes = {
        mobile: "pave",
        desktop: "paveStd",
      };
      break;

    case 96775:
      sizes = {
        mobile: "paveStandardMobile",
        desktop: "inText",
      };
      break;

    case 96776:
      sizes = {
        mobile: "pushColumn",
        desktop: "paveStandard",
      };
      break;

    case 94933:
      sizes = {
        mobile: "pave",
        desktop: "pave",
      };
      break;

    case 94952:
      sizes = {
        mobile: "pave",
        desktop: "bandeau",
      };
      break;
  }
  return sizes;
};

const getMailChimpEndpoint = id => {
  switch (id) {
    case "curiosity":
      return process.env.GATSBY_MAILCHIMP_ENDPOINT_CURIOSITY;

    case "trois_couleurs":
      return process.env.GATSBY_MAILCHIMP_ENDPOINT_TROIS_COULEURS;

    case "institut":
      return process.env.GATSBY_MAILCHIMP_ENDPOINT_INSTITUT;

    case "paradiso":
      return process.env.GATSBY_MAILCHIMP_ENDPOINT_PARADISO;

    case "cinema":
      return process.env.GATSBY_MAILCHIMP_ENDPOINT_MK2;
  }
};

const getLogoTop = category => {
  switch (category) {
    case "curiosity":
      return {
        sm: 1.5,
        md: 1.5,
      };

    default:
      return {
        sm: 4,
        md: 4,
      };
  }
};

const getModalVideoTemplate = player => {
  switch (player) {
    case "InPlayer":
      return "inPlayer";

    case "Embed":
      return "embedVideo";

    case "Vimeo":
    case "Youtube":
    default:
      return "overlayVideo";
  }
};

const logos = {
  cinema: {
    label: "mk2cinema",
    width: 105,
    height: 25,
    fill: colors.white,
  },
  curiosity: {
    label: "logoCuriosity",
    width: 118.98,
    height: 20,
    fill: colors.white,
  },
  institut: {
    label: "logoInstitut",
    width: 118.98,
    height: 20,
    fill: colors.white,
  },
  paradiso: {
    label: "logoParadiso",
    width: 180,
    height: 20,
    fill: colors.white,
  },
  trois_couleurs: {
    label: "logoTroiscouleurs",
    width: 118.98,
    height: 20,
    fill: colors.white,
  },
  mk2plus: {
    label: "mk2plus",
    width: 47,
    height: 20,
    fill: colors.white,
  },
};

const getLogo = category => {
  switch (category) {
    case "curiosity":
      return "logoCuriosity";

    case "troiscouleurs":
    case "trois_couleurs":
      return "logoTroiscouleurs";

    case "paradiso":
      return "logoParadiso";

    case "mk2plus":
      return "mk2plus";

    case "institut":
      return "logoInstitut";

    default:
      return "mk2";
  }
};

const getFooterLogos = (type, footerData) => {
  const {
    cinemaLink,
    curiosityLink,
    institutLink,
    plusLink,
    troiscouleursLink,
  } = footerData;

  logos.cinema.link = cinemaLink;
  logos.curiosity.link = curiosityLink;
  logos.institut.link = institutLink;
  logos.trois_couleurs.link = troiscouleursLink;
  logos.mk2plus.link = plusLink;

  const firstRow = [logos.cinema],
    secondRow = [];

  switch (type) {
    case "curiosity":
      firstRow.push(logos.institut);
      secondRow.push(logos.trois_couleurs);
      break;
    case "institut":
      firstRow.push(logos.curiosity);
      secondRow.push(logos.trois_couleurs);
      break;
    case "troiscouleurs":
      firstRow.push(logos.institut);
      secondRow.push(logos.curiosity);
      break;
    default:
      break;
  }

  secondRow.push(logos.mk2plus);

  return [firstRow, secondRow];
};

const accentsMap = {
  a: "á|à|ã|â|À|Á|Ã|Â",
  e: "é|è|ê|É|È|Ê",
  i: "í|ì|î|Í|Ì|Î",
  o: "ó|ò|ô|õ|Ó|Ò|Ô|Õ",
  u: "ú|ù|û|ü|Ú|Ù|Û|Ü",
  c: "ç|Ç",
  n: "ñ|Ñ",
};

const slugify = text =>
  Object.keys(accentsMap).reduce(
    (acc, cur) =>
      acc.replace(new RegExp(accentsMap[cur], "g"), cur).replace(/\s+/g, "-"),
    text
  );

// Emotion Theme

const themes = {
  curiosity: {
    label: "curiosity",
    primary: colors.curiosity,
    color: colors.white,
    foregroundColor: colors.white,
    backgroundColor: colors.black,
    backgroundColorLight: colors.darkgrey2,
    logo: "logoCuriosity",
    logoOffsetTop: {
      sm: 1.5,
      md: 1.5,
    },
    menuBgColor: colors.darkgrey2,
    menuColor: colors.white,
    menuSeparatorColor: colors.darkgrey3,
    filterBackgroundColor: colors.darkgrey4,
    filterColor: colors.white,
  },
  trois_couleurs: {
    label: "troiscouleurs",
    primary: colors.troiscouleurs,
    color: colors.black,
    foregroundColor: colors.black,
    backgroundColor: colors.white,
    backgroundColorLight: colors.white,
    logo: "logoTroiscouleurs",
    logoOffsetTop: {
      sm: 4,
      md: 4,
    },
    menuBgColor: colors.white,
    menuColor: colors.black,
    menuSeparatorColor: hexRgba(colors.mediumgrey2, 0.15),
    filterBackgroundColor: colors.lightgrey4,
    filterColor: colors.black,
  },
  institut: {
    label: "institut",
    primary: colors.institut,
    color: colors.white,
    foregroundColor: colors.black,
    backgroundColor: colors.white,
    backgroundColorLight: colors.white,
    logo: "logoInstitut",
    logoOffsetTop: {
      sm: 4,
      md: 4,
    },
    menuBgColor: colors.darkgrey2,
    menuColor: colors.white,
    menuSeparatorColor: hexRgba(colors.mediumgrey2, 0.15),
    filterBackgroundColor: colors.lightgrey4,
    filterColor: colors.black,
  },
};

export {
  themes,
  cssVariables,
  colors,
  breakpoints,
  mediaquery,
  iconSize,
  zIndexes,
  logos,
  hexToRgb,
  hexRgba,
  rem,
  scaleSpacing,
  getLogo,
  getLogoTop,
  getFooterLogos,
  getModalVideoTemplate,
  getSearchType,
  getArticleIcon,
  slugify,
  getMailChimpEndpoint,
  getAdImageFormat,
};
