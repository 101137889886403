/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { memo } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql, withPrefix } from "gatsby";

function SEO(seoData) {
  const { metaDescription, meta, metaTitle, metaImage, url, status } =
    seoData || {};

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaLang = "fr-fr";
  const title = metaTitle || site?.siteMetadata?.title;
  const description = metaDescription || site?.siteMetadata?.description;
  const metaUrl = site?.siteMetadata?.siteUrl + (url ? url : "");
  const metaOgImage = metaImage || withPrefix("og.png");
  const prerenderStatus = status || "200";

  return (
    <Helmet
      htmlAttributes={{
        lang: metaLang,
      }}
      title={title}
      meta={[
        {
          name: `robots`,
          content: "max-snippet:-1",
        },
        {
          name: `robots`,
          content: "max-image-preview:standard",
        },
        {
          name: `robots`,
          content: "max-video-preview:-1",
        },
        {
          name: `prerender-status-code`,
          content: prerenderStatus,
        },
        {
          name: `prerender-header`,
          content: `Location: ${metaUrl}`,
        },
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaOgImage,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:image`,
          content: metaOgImage,
        },
        {
          name: `twitter:creator`,
          content: `@https://twitter.com/trois_couleurs/`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:site`,
          content: `@Trois_Couleurs`,
        },
      ].concat(meta)}
      link={[
        {
          rel: "preconnect",
          href: "https://www.google-analytics.com",
        },
        {
          rel: "preconnect",
          href: "https://fonts.googleapis.com/",
        },
        {
          rel: "preconnect",
          href: "https://fonts.gstatic.com",
          crossOrigin: "true",
        },
        {
          rel: "preconnect",
          href: "https://par-mk2-institut.prismic.io",
          crossOrigin: "true",
        },
        {
          rel: "preconnect",
          href: "www14.smartadserver.com",
          crossOrigin: "true",
        },
      ]}
    />
  );
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  metaDescription: ``,
};

SEO.propTypes = {
  metaDescription: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  metaTitle: PropTypes.string,
  metaImage: PropTypes.string,
  locale: PropTypes.string,
  url: PropTypes.string,
};

export default memo(SEO);
