import { useRef } from "react";
import useOnScroll from "./useOnScroll";

const isBrowser = typeof window !== "undefined";

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

export default function useScrollPosition(effect, options = {}) {
  const position = useRef(getScrollPosition(options));

  useOnScroll(() => {
    const currPos = getScrollPosition(options);
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
  });
}
