import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";

const s1 = keyframes`
    0%, 90% {
      transform: rotate3d(0, 0, 0, 0deg);
    }
    94% {
      transform: rotate3d(1, -1, 0, 90deg);
    }
    98%,100% {
      transform: rotate3d(0, 0, 0, 0deg);
    }
`;

const s2 = keyframes`
0%, 5% {
  transform: translateX(0px) translateY(0px);
}
7%{
  transform: translateX(-6px) translateY(6px);
}
30%{
  transform: translateX(6px) translateY(-6px);
}
32%{
  transform: translateX(-6px) translateY(6px);
}
60%{
  transform: translateX(6px) translateY(-6px);
}
62%{
  transform: translateX(-6px) translateY(6px);
}
90%{
  transform: translateX(6px) translateY(-6px);
}
98%,100% {
  transform: translateX(0px) translateY(0px);
}
`;

const EyeIconWrapper = styled.div`
  transition: transform 1s ease;
  transform: rotate(45deg);
  & > div {
    ${({ active }) =>
      !active &&
      `
  animation-play-state: paused !important;
`}
    animation: ${s1} 8s infinite;
  }
`;

const EyeIconInnerWrapper = styled.div`
  transition: transform 1s ease;
`;

const StyledEyeIcon = styled.div`
  margin: 0 auto;
  width: 20px;
  height: 20px;
  border-radius: 85% 0px;
  border: 1px ${({ color }) => color} solid;
  transition: transform 1s;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 30px;
    background: ${({ color }) => color};
    top: 25px;
    ${({ active }) =>
      !active &&
      `
    animation-play-state: paused !important;
  `}
    animation: ${s2} 8s infinite;
    top: calc(50% - 1.6px);
    left: calc(50% - 1.6px);
  }
`;

const EyeIcon = ({ active, color }) => {
  return (
    <EyeIconWrapper active={active}>
      <EyeIconInnerWrapper>
        <StyledEyeIcon active={active} color={color} />
      </EyeIconInnerWrapper>
    </EyeIconWrapper>
  );
};

EyeIcon.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
};

export default memo(EyeIcon);
