import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";
import { css } from "@emotion/core";

import { NotoLink } from "../Typography";
import { colors } from "../../../../styles/theme";

const linkDefaultStyle = css`
  cursor: pointer;
  text-decoration-line: none;
`;

const linkTextStyle = css`
  ${NotoLink};
  ${linkDefaultStyle}
  text-decoration-line: underline;
`;

export const linkStyle = linkTheme => {
  switch (linkTheme) {
    case "area":
      return css`
        ${linkDefaultStyle}
        color: ${colors.black};
      `;

    case "dark":
      return css`
        ${linkTextStyle}
        color: ${colors.white};
      `;

    default:
      return css`
        ${linkTextStyle}
        color: ${colors.black};
      `;
  }
};

const Link = ({
  link,
  children,
  className,
  activeClassName,
  partiallyActive,
  activeStyle,
  linkTheme,
}) => {
  if (!link || link?.isBroken) return null;

  const { url } = link || null;

  const internal = /^\/(?!\/)/.test(url);
  const file = /\.[0-9a-z]+$/i.test(url);

  if (internal && !file) {
    return (
      <GatsbyLink
        to={url}
        className={className}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        activeStyle={activeStyle}
        css={linkStyle(linkTheme)}
      >
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a
      href={url}
      className={className}
      target="_blank"
      rel="noopener"
      css={linkStyle(linkTheme)}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  link: PropTypes.object.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.bool,
  activeStyle: PropTypes.object,
  linkTheme: PropTypes.string,
};

Link.defaultProps = {
  activeClassName: "active",
  activeStyle: null,
  partiallyActive: false,
};

export default React.memo(Link);
