import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import { breakpoints, colors } from "../../../../styles/theme";

const Image = ({
  source,
  sizes,
  mobileSizes,
  durationFadeIn,
  className,
  title,
  alt,
  objectFit,
  style,
  imgStyle,
  isFixed,
}) => {
  if (!source.fluid && !source.fixed) return null;
  const isCover = objectFit === "cover";
  const mobileFluidSource = source?.thumbnails?.mobile?.fluid;
  const mobileFixedSource = source?.thumbnails?.mobile?.fixed;

  const fluidSources = mobileFluidSource
    ? [
        { ...mobileFluidSource },
        {
          ...source.fluid,
          sizes: `(max-width: ${breakpoints.md}px) ${mobileSizes}, ${sizes}`,
          media: `(min-width: ${breakpoints.md}px)`,
        },
      ]
    : [source.fluid];

  const fixedSources = mobileFixedSource
    ? [
        { ...mobileFixedSource },
        {
          ...source.fixed,
          sizes: `(max-width: ${breakpoints.md}px) ${mobileSizes}, ${sizes}`,
          media: `(min-width: ${breakpoints.md}px)`,
        },
      ]
    : [source.fixed];

  return (
    <StyledImg
      fluid={!isFixed ? fluidSources : null}
      fixed={isFixed ? fixedSources : null}
      className={className}
      title={title}
      alt={alt || source.alt}
      objectFit={objectFit}
      objectPosition={isCover ? "50% 50%" : "unset"}
      style={isCover ? { width: "100%", height: "100%", ...style } : style}
      imgStyle={imgStyle}
      durationFadeIn={durationFadeIn}
    />
  );
};

Image.propTypes = {
  source: PropTypes.object,
  sizes: PropTypes.string,
  mobileSizes: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  alt: PropTypes.string,
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  style: PropTypes.object,
  imgStyle: PropTypes.object,
  durationFadeIn: PropTypes.number,
  isFixed: PropTypes.bool,
};

Image.defaultProps = {
  sizes: "100vw",
  mobileSizes: "100vw",
  title: "",
  alt: "",
  durationFadeIn: 500,
  isFixed: false,
};

export default React.memo(Image);

const StyledImg = styled(Img)`
  background-color: ${colors.black};
`;
