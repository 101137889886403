import { css } from "@emotion/core";
import { mediaquery, rem, scaleSpacing, zIndexes } from "./theme";
import inplayerStyles from "./inplayerStyles";

const defaultOverlay = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  z-index: ${zIndexes.modal};
`;

export const outerStyle = css`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${scaleSpacing(6)} calc(var(--external-gutter));
  overflow: auto;

  ${mediaquery.md(css`
    position: relative;
    margin: auto;
    height: auto;
    width: calc(var(--col) * 14 + var(--gutter-size));
    max-width: ${rem(900)};
    border-radius: ${scaleSpacing(1.5)};
    padding: ${scaleSpacing(16)} calc(var(--external-gutter));
  `)};
`;

export const innerFullScreenStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const modalStyles = css`
  .ReactModal__Body--open,
  .Menu__Body--open {
    overflow: hidden;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;

    &.mk2-modal {
      ${defaultOverlay}
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.mk2-modal-scrollable {
      overflow: auto;
    }
    &.mk2-modal-fullscreen {
      ${defaultOverlay}
      background-color: rgba(0, 0, 0, 1);
    }
    &.mk2-modal-search {
      ${defaultOverlay}
      background-color: rgba(0, 0, 0, 0.8);
      height: auto;
      .modal {
        overflow: visible;
        height: auto;
      }
    }

    .modal {
      width: 100%;
      height: 100%;
      outline: none;
      overflow: hidden;
      ${mediaquery.md(css`
        width: auto;
        height: auto;
      `)};
    }
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .ReactModal__Content {
    transform: scale(0.9);
    transition: transform 300ms ease-in-out;

    &.ReactModal__Content--after-open {
      position: relative;
      top: auto !important;
      left: auto !important;
      right: auto !important;
      bottom: auto !important;
    }

    .mk2-modal && {
      box-sizing: border-box;
      position: relative;
      background-color: transparent;
    }

    .mk2-modal-fullscreen && {
      box-sizing: border-box;
      position: relative;
      margin: 0 auto;
      background-color: transparent;
      max-width: 100%;
      min-height: 100%;
    }
    .inplayer-paywall-asset,
    .iframe-container {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 90%;

      & > div {
        height: 100%;
      }

      .flowplayer {
        position: absolute;
        height: 100%;
        background-color: black;
        background-size: contain;
        &:not(.is-starting) {
          background-size: 0 0;
        }
      }

      iframe {
        width: calc(100% - 96px);
        height: calc(100% - 96px);
        position: relative;
        top: 48px;
        left: 48px;
      }
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  .ReactModal__Content--after-open {
    transform: scale(1);
  }

  .ReactModal__Content--before-close {
    transform: scale(0.9);
  }

  /* Scrollable modal */

  ${mediaquery.md(css`
    .ReactModal__Content {
      .mk2-modal-scrollable && {
        display: flex;
        position: absolute !important;
        top: ${scaleSpacing(0)} !important;
        bottom: ${scaleSpacing(15)}!important;
        height: calc(100% - 120px) !important;
        min-height: 920px !important;
      }
    }
  `)}

  /* Inplayer Modal */

  ${inplayerStyles}
`;
