import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import Svg from "../../Atoms/Svg";

import {
  colors,
  scaleSpacing,
  rem,
  mediaquery,
} from "../../../../styles/theme";
import { NotoBodySmall } from "../../Atoms/Typography";

const SectionWrapper = styled.div`
  position: relative;
  margin-bottom: ${scaleSpacing(6)};
  padding-left: ${rem(32)};

  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${mediaquery.md(css`
    display: block;
    padding-left: var(--col);
    &:last-of-type {
      margin-bottom: ${scaleSpacing(6)};
    }
  `)};

  &:before {
    content: "";
    position: absolute;
    width: ${scaleSpacing(4)};
    height: ${scaleSpacing(4)};
    border: 1px solid ${colors.black};
    top: 0;
    left: 0;
  }
  ${mediaquery.md(css`
    &:before {
      top: 0;
      left: calc(var(--col) - ${rem(32)});
    }
  `)};

  &:after {
    position: absolute;
    top: ${rem(-1)};
    left: ${rem(6)};

    content: "";
    width: ${scaleSpacing(4)};
    height: ${scaleSpacing(1.5)};
    border-left: 2px solid ${colors.black};
    border-bottom: 2px solid ${colors.black};
    transform: rotate(-45deg);
    transition: opacity 100ms;
    opacity: 0;
    ${({ isChecked }) =>
      isChecked &&
      css`
        opacity: 1;
      `};
  }
  ${mediaquery.md(css`
    &:after {
      left: calc(var(--col) - ${rem(26)});
    }
  `)};
`;

const Description = styled.span`
  display: inline-block;
  ${NotoBodySmall};
  color: ${colors.mediumgrey1};
`;

const ImageWrapper = styled.div`
  display: block;
  margin-bottom: ${scaleSpacing(4)};

  img {
    display: block;
    width: 100%;
  }
`;

const NewsletterModalSection = ({
  description,
  logo,
  id,
  onSelect,
  hasReset,
}) => {
  const [isChecked, setIsChecked] = useState(true);

  const handleClick = useCallback(() => {
    onSelect(id);
    setIsChecked(!isChecked);
  }, [onSelect, id, isChecked]);

  useEffect(() => {
    if (hasReset) {
      setIsChecked(false);
    }
  }, [hasReset]);

  return (
    <SectionWrapper onClick={handleClick} isChecked={isChecked}>
      <ImageWrapper>
        {logo && (
          <Svg
            name={logo.label}
            size={logo.width}
            height={logo.height}
            fill={colors.black}
          />
        )}
      </ImageWrapper>
      <Description>{description}</Description>
    </SectionWrapper>
  );
};

NewsletterModalSection.propTypes = {
  logo: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string,
  onSelect: PropTypes.func,
  hasReset: PropTypes.bool,
};

NewsletterModalSection.defaultProps = {
  hasReset: false,
};

export default React.memo(NewsletterModalSection);
