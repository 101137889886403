import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import CardGridItem from "../CardGridItem";
import {
  mediaquery,
  scaleSpacing,
  colors,
  zIndexes,
} from "../../../../styles/theme";
import { OswaldOverline1, OswaldOverline2 } from "../../Atoms/Typography";
import { RichText } from "prismic-reactjs";

// Wrapper

const Wrapper = styled.section`
  position: relative;
  padding: ${scaleSpacing(6)} var(--external-gutter) ${scaleSpacing(4)};
  margin: 0;
  z-index: ${zIndexes.middle};
  background-color: ${({ bgColor }) => bgColor};

  ${({ columns }) =>
    columns === 4 &&
    css`
      padding: ${scaleSpacing(8)} var(--external-gutter) ${scaleSpacing(25)};
    `}
`;

// List

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${scaleSpacing(16)};
  margin-bottom: ${scaleSpacing(16)};

  ${mediaquery.md(css`
    gap: ${scaleSpacing(20)};
    margin-bottom: ${scaleSpacing(20)};
  `)};

  ${({ columns }) =>
    columns === 4 &&
    css`
      margin-bottom: 0;
      ${mediaquery.md(css`
        margin-bottom: 0;
      `)};
    `}

  ${({ columns }) =>
    columns > 1 &&
    css`
      ${mediaquery.sm(css`
        gap: ${scaleSpacing(10)} ${scaleSpacing(8)};
        grid-template-columns: repeat(${columns - 2}, 1fr);
      `)}

      ${mediaquery.md(css`
        grid-template-columns: repeat(${columns - 1}, 1fr);
      `)}

      ${mediaquery.l(css`
        grid-template-columns: repeat(${columns}, 1fr);
      `)}
    `}
`;

const Title = styled.h2`
  ${OswaldOverline2};
  text-transform: uppercase;
  padding-top: ${scaleSpacing(8)};
  padding-bottom: ${scaleSpacing(8)};
  color: ${({ color }) => color};

  ${mediaquery.sm(css`
    ${OswaldOverline1};
  `)};
`;

const CardGrid = ({ title, items, color, bgColor, columns, type, animate }) => {
  return (
    <Wrapper bgColor={bgColor} columns={columns}>
      {!!title && (
        <Title color={color}>
          {typeof title === "string" ? title : <RichText data={title} />}
        </Title>
      )}
      <List columns={columns}>
        {!!items &&
          items.map(item => {
            return (
              <CardGridItem
                {...item}
                key={item?.uid}
                type={type}
                animate={animate}
              />
            );
          })}
      </List>
    </Wrapper>
  );
};

CardGrid.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array.isRequired,
  as: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  columns: PropTypes.number,
  type: PropTypes.string,
  animate: PropTypes.bool,
};
CardGrid.defaultProps = {
  color: colors.white,
  bgColor: colors.darkgrey2,
  columns: 1,
  animate: false,
};

export default React.memo(CardGrid);
