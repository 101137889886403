import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import ReactModal from "react-modal";
import { colors, rem, scaleSpacing, zIndexes } from "../../../../styles/theme";
import { innerFullScreenStyle, outerStyle } from "../../../../styles/modal";
import IconSvg from "../../Atoms/Svg";
import Button from "../../Atoms/Button";

ReactModal.setAppElement("#___gatsby");

const Outer = styled.div`
  width: 100%;
  height: 100%;
  ${({ isFullScreen }) => !isFullScreen && outerStyle};
  background-color: ${({ background, isFullScreen }) =>
    isFullScreen ? "transparent" : background ? background : colors.white};
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  ${({ isFullScreen }) => isFullScreen && innerFullScreenStyle};
`;

const CloseButton = styled(Button)`
  z-index: ${zIndexes.modal};
  position: absolute;
  top: ${scaleSpacing(4)};
  right: ${scaleSpacing(4)};
  background: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem(22)};
  height: ${rem(22)};
  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `};
`;

const Modal = ({
  children,
  className,
  handleOverlayRef,
  isOpen,
  isFullScreen,
  isSearch,
  onClose,
  outerBgColor,
  isDark,
  isPanel,
  isScrollable,
}) => {
  const handleGetOverlayRef = node => {
    handleOverlayRef(node);
  };

  const [overlayClassName, setOverlayClassName] = useState(null);

  useEffect(() => {
    if (isPanel) {
      setOverlayClassName("inplayer-paywall");
    } else if (isSearch) {
      setOverlayClassName("mk2-modal mk2-modal-search");
    } else if (isFullScreen) {
      setOverlayClassName("mk2-modal-fullscreen");
    } else if (isScrollable) {
      setOverlayClassName("mk2-modal mk2-modal-scrollable");
    } else {
      setOverlayClassName("mk2-modal");
    }
  }, [isFullScreen, isPanel, isSearch, isScrollable]);

  const scrollY = useRef(0);

  useEffect(() => {
    if (isOpen) {
      scrollY.current = window.scrollY;
      document.body.style.width = "100vw";
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollY.current}px`;
    }
  }, [isOpen]);

  const onAfterClose = () => {
    document.body.style.position = "";
    document.body.style.top = "";
    document.body.style.width = "";
    window.scrollTo(0, scrollY.current);
  };

  return (
    <ReactModal
      openTimeoutMS={300}
      closeTimeoutMS={300}
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterClose={onAfterClose}
      overlayRef={node => handleGetOverlayRef(node)}
      className={className}
      overlayClassName={overlayClassName}
      parentSelector={() => document.querySelector("#modalsRoot")}
    >
      <Outer isFullScreen={isFullScreen || isSearch} background={outerBgColor}>
        <CloseButton
          onClick={onClose}
          isPanel={isPanel}
          ariaLabel="close"
          hide={isSearch}
        >
          <IconSvg
            name="cross"
            size={18}
            fill={isDark ? colors.white : colors.darkgrey2}
          />
        </CloseButton>
        <Inner isFullScreen={isFullScreen}>{children}</Inner>
      </Outer>
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  handleOverlayRef: PropTypes.func,
  isFullScreen: PropTypes.bool,
  isDark: PropTypes.bool,
  isSearch: PropTypes.bool,
  isPanel: PropTypes.bool,
  isScrollable: PropTypes.bool,
  outerBgColor: PropTypes.string,
  className: PropTypes.string,
};
Modal.defaultProps = {
  handleOverlayRef: () => {},
  isFullScreen: false,
  outerBgColor: "#ffffff",
  className: "modal",
  isDark: false,
  isPanel: false,
  isSearch: false,
};

export default Modal;
