import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { connectStateResults } from "react-instantsearch-dom";
import { css, keyframes } from "@emotion/core";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";

import { OswaldH6, NotoLink } from "../../Atoms/Typography";
import { mediaquery, scaleSpacing } from "../../../../styles/theme";

import Link from "../../Atoms/Link";

const appear = keyframes`
	0% {
		opacity: 0;
	}
  100% {
		opacity: 1;
	}
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: ${scaleSpacing(16)};
  padding: 0 calc(var(--external-gutter));
  opacity: ${({ show }) => (show ? 1 : 0)};
  animation: 300ms ease 0s normal forwards 1 ${appear};
  color: ${({ color }) => color};
`;

const Text = styled.h3`
  ${OswaldH6}
  color: inherit;
  margin: ${scaleSpacing(6)} 0;

  ${mediaquery.md(css`
    margin: 0 0 ${scaleSpacing(6)} 0;
  `)};
`;
const BackLink = styled(Link)`
  ${NotoLink}
  color: inherit;
`;
const SearchStateResult = ({ searchResults, children, searchState }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  if (["", undefined].includes(searchState.query)) return null;
  return searchResults?.nbHits !== 0 && searchResults?.query?.length > 2 ? (
    children
  ) : (
    <Wrapper color={theme?.foregroundColor}>
      <Text>{t("search_text_no_result")}</Text>
      <BackLink linkTheme="dark" link={{ url: "/" }}>
        {t("search_backButton_ctaLabel")}
      </BackLink>
    </Wrapper>
  );
};

SearchStateResult.propTypes = {
  children: PropTypes.object,
  searchResults: PropTypes.object,
  searchState: PropTypes.object,
  show: PropTypes.bool,
};

const CustomStateNoResult = connectStateResults(SearchStateResult);

export default CustomStateNoResult;
