export default data => {
  const {
    id,
    objectID,
    uid,
    type,
    catId,
    catLabel,
    category,
    format,
    url,
    image,
    picture,
    description,
    title,
    guest_title,
    collection_title,
    collection_subtitle,
    director,
    free,
    guest_subtitle,
    subtitle,
    label,
  } = data;

  return {
    id: id || objectID,
    uid,
    catId,
    type,
    typeLabel: catId !== "medias" && catLabel,
    label: label || (catId !== "medias" && catLabel),
    category: category || format,
    picture:
      image?.thumbnails?.mobile ||
      picture?.thumbnails?.mobile ||
      image ||
      picture,
    title: guest_title || collection_title || title || description?.text,
    subtitle: director || collection_subtitle || guest_subtitle || subtitle,
    free,
    ctaLink: {
      url,
    },
  };
};
