import { colors } from "../../../../styles/theme";
import { useStaticQuery, graphql } from "gatsby";

export default datamock => {
  const data = !datamock
    ? useStaticQuery(graphql`
        query HeaderQuery {
          ...SiteHeader
          ...HamburgerMenu
          ...Configuration
        }
      `)
    : datamock;

  if (!data && (!data.prismicHamburgerMenu || !data.prismicHamburgerMenu)) {
    return null;
  }

  const website = process.env.GATSBY_WEBSITE;

  const headerNav = data.allPrismicSiteHeader?.edges?.filter(
    ({ node }) => node.data.site === website
  )[0].node.data;

  const siteConfig = data.allPrismicSiteConfiguration?.edges?.find(
    ({ node }) => node.data.site === website
  ).node.data;

  const headerBurger = data?.prismicHamburgerMenu?.data;

  const headerData = {
    ...headerNav,
    ...headerBurger,
  };

  const {
    site_navigation,
    cinemalink,
    cinemadescription,
    curiositylink,
    curiositydescription,
    troiscouleurslink,
    troiscouleursdescription,
    institutlink,
    institutdescription,
  } = headerData || {};

  return {
    menu: site_navigation,
    homeLink: {
      url: "/",
    },
    showNewsletterIcon: siteConfig.show_newsletter_icon,
    showProfileIcon: siteConfig?.show_profile_icon,
    showSearchIcon:
      siteConfig?.show_search_icon &&
      process.env.GATSBY_ALGOLIA_ENABLE_SEARCH === "true",
    sections: [
      {
        link: cinemalink,
        description: cinemadescription,
        logo: "mk2cinema",
        logoW: 127,
        logoH: 23,
        color: colors.cinema,
        isCurrent: false,
      },
      {
        link: institutlink,
        description: institutdescription,
        logo: "logoInstitut",
        logoW: 140,
        logoH: 23.14,
        color: colors.institut,
        id: "institut",
      },
      {
        link: curiositylink,
        description: curiositydescription,
        logo: "logoCuriosity",
        logoW: 140,
        logoH: 23.14,
        color: colors.curiosity,
        id: "curiosity",
      },
      {
        link: troiscouleurslink,
        description: troiscouleursdescription,
        logo: "logoTroiscouleurs",
        logoW: 140,
        logoH: 23.14,
        color: colors.troiscouleurs,
        id: "troiscouleurs",
      },
    ],
  };
};
