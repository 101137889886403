import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { connectSearchBox } from "react-instantsearch-dom";
import { navigate } from "gatsby";

import { colors } from "../../../../styles/theme";
import Button from "../../Atoms/Button";
import { useTranslation } from "react-i18next";
import IconSvg from "../../Atoms/Svg";
import { createURL } from "../../../utils/url";

const Wrapper = ({ withWrapper, wrapper, children }) =>
  withWrapper ? wrapper(children) : children;

const SearchBoxWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

const SearchBox = ({
  handleClose,
  handleOnFocusInput,
  isInModal,
  className,
  // exposed from connectSearchBox
  refine,
  initialSearch,
}) => {
  const inputRef = useRef();
  const [currentValue, setCurrentValue] = useState(initialSearch);
  const { t } = useTranslation();

  useEffect(() => {
    inputRef.current.focus();
  }, [handleOnFocusInput]);

  useEffect(() => {
    const t = setTimeout(() => {
      refine(currentValue);
    }, 200);
    return () => {
      clearTimeout(t);
    };
  }, [currentValue, refine]);

  const handleOnClear = useCallback(() => {
    if (currentValue) {
      setCurrentValue("");
    } else if (isInModal) {
      handleClose();
    }
  }, [currentValue, handleClose, isInModal]);

  const onSubmit = e => {
    e.preventDefault();
    const redirectUrl = createURL({ query: currentValue }, "search");
    navigate(redirectUrl);
  };

  return (
    <Wrapper
      withWrapper={!isInModal}
      wrapper={children => <SearchBoxWrapper>{children}</SearchBoxWrapper>}
    >
      <form
        noValidate
        action=""
        role="search"
        className={className}
        onSubmit={onSubmit}
      >
        <input
          ref={inputRef}
          className="SearchInput"
          type="text"
          placeholder={t("search_placeholder")}
          aria-label="Search"
          onChange={e => setCurrentValue(e.currentTarget.value)}
          value={currentValue}
          autoFocus
        />

        <Button className="SearchIcons" type="reset" onClick={handleOnClear}>
          <IconSvg name="cross" size={18} height={18} fill={colors.black} />
        </Button>
      </form>
    </Wrapper>
  );
};

SearchBox.propTypes = {
  refine: PropTypes.func,
  className: PropTypes.string,
  isInModal: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOnFocusInput: PropTypes.bool,
  initialSearch: PropTypes.string,
};

const CustomSearchBox = connectSearchBox(SearchBox);

export default CustomSearchBox;
