import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import ScrollLock, { TouchScrollable } from "react-scrolllock";
import { useTransition, animated } from "react-spring";

import Svg from "../../Atoms/Svg";
import Button from "../../Atoms/Button";

import {
  scaleSpacing,
  mediaquery,
  hexRgba,
  colors,
  zIndexes,
} from "../../../../styles/theme";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndexes.modal};
  overflow-y: auto;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${hexRgba(colors.black, 0.5)};
  cursor: pointer;
`;

const Content = styled(animated.div)`
  min-height: 100%;
  position: relative;
  background: ${colors.white};
  padding: ${scaleSpacing(20)} var(--external-gutter);
  margin-top: ${scaleSpacing(10)};
  border-top-left-radius: ${scaleSpacing(3)};
  border-top-right-radius: ${scaleSpacing(3)};

  ${mediaquery.md(css`
    width: 800px;
    margin: 0 auto;
    margin-top: ${scaleSpacing(20)};
    padding: ${scaleSpacing(20)};
    box-shadow: 0px -4px 60px ${hexRgba(colors.black, 0.25)};
  `)};
`;

const CloseButtonStyles = css`
  position: sticky;
  width: ${scaleSpacing(12)};
  height: ${scaleSpacing(16)};
  top: ${scaleSpacing(10)};
  margin-top: -14%;
  margin-left: 85%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999;

  ${mediaquery.sm(css`
    margin-top: -8%;
    margin-left: 96%;
  `)};

  ${mediaquery.md(css`
    margin-top: -8%;
    margin-left: 100%;
  `)};
`;

const Popin = ({ shown, hide, children, ...rest }) => {
  const transition = useTransition(shown, {
    from: { opacity: 0, y: 200 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 200 },
  });

  return (
    <div {...rest}>
      <ScrollLock isActive={shown} />
      {transition((style, item) => {
        return (
          item && (
            <TouchScrollable>
              <Wrapper>
                <Overlay style={{ opacity: style.opacity }} onClick={hide} />
                <Content style={style}>
                  <Button
                    buttonTheme="primaryGrey"
                    css={CloseButtonStyles}
                    onClick={hide}
                  >
                    <Svg name="cross" fill={colors.black} />
                  </Button>
                  {children}
                </Content>
              </Wrapper>
            </TouchScrollable>
          )
        );
      })}
    </div>
  );
};

Popin.propTypes = {
  shown: PropTypes.bool,
  children: PropTypes.node,
  hide: PropTypes.func.isRequired,
};

Popin.defaultProps = {
  shown: false,
};

export default React.memo(Popin);
