const htmlEntities = [
  ["amp", "&"],
  ["apos", "'"],
  ["#x27", "'"],
  ["#x2F", "/"],
  ["#39", "'"],
  ["#47", "/"],
  ["lt", "<"],
  ["gt", ">"],
  ["nbsp", " "],
  ["quot", '"'],
  ["rsquo", "’"],
  ["#8211", "–"],
];

export function decodeString(str) {
  return htmlEntities.reduce(
    (s, [entity, char]) => s.replace(new RegExp(`&${entity};`, "g"), char),
    str
  );
}
