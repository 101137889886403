import { css } from "@emotion/core";
import { cssVariables, colors } from "./theme";
import { modalStyles } from "./modal";
import ads from "./ads";
import cookiebot from "./cookiebot";

const globalStyles = css`
  ${cssVariables};
  ${cookiebot};
  ${modalStyles};
  ${ads};

  html {
    background-color: ${colors.black};
  }
  body {
    background-color: ${colors.white};
  }

  main {
    background-color: white;
    position: relative;
  }

  /* Transition link css fixes */
  .tl-edges {
    overflow-x: visible;
  }
  .tl-wrapper {
    background-color: white;
  }

  .gatsby-plugin-transition-link-portal {
    pointer-events: none;
  }
`;

export default globalStyles;
