import { colors } from "../../../../styles/theme";
import {
  adapter as figureAdapter,
  articleAdapter,
} from "../../Molecules/Figure";

export default ({ films }, { t }) => {
  return {
    items:
      films &&
      films.map(film => {
        const { data: filmData, id, uid, url } = film?.film?.document || {};
        return {
          ...figureAdapter({ id, uid, url, ...filmData }, { t }),
        };
      }),
  };
};

export const articlesSliceAdapter = ({ primary, items }) => {
  const website = process.env.GATSBY_WEBSITE;

  return {
    headingTitle: primary.articles_title?.raw,
    columns: 2,
    showIcon: false,
    showWebsiteLabel: true,
    showDescription: website !== "trois_couleurs",
    showMeta: website !== "trois_couleurs",
    hasTagInfos: website === "trois_couleurs",
    items: items
      ?.map(({ article }) => {
        const articleData = article?.document?.data || {};
        const { id, uid, type, url } = article?.document || {};
        const themes = articleData?.themes?.map(
          ({ theme }) => theme && theme.document && theme.document.data
        );

        return {
          ...articleAdapter({ ...articleData, id, uid, type, url, themes }),
        };
      })
      .filter(item => !!item.uid),
  };
};

export const articlesFigureListAdapter = ({
  lastArticles,
  articlesPush,
  maxitems = 4,
}) => {
  const filteredArticlesPush =
    articlesPush?.filter(article => article?.article?.document) || [];

  const selectedArticles = (articlesPush
    ? [...new Set([...filteredArticlesPush, ...lastArticles])]
    : [...lastArticles]
  ).slice(0, maxitems);

  const website = process.env.GATSBY_WEBSITE;

  return {
    showIcon: false,
    showWebsiteLabel: true,
    showDescription: website !== "trois_couleurs",
    showMeta: website !== "trois_couleurs",
    hasTagInfos: website === "trois_couleurs",
    items: selectedArticles
      ?.map(article => {
        const articleData =
          article?.node?.data || article?.article?.document?.data || {};

        const { id, uid, type, url } =
          article?.node || article?.article?.document || {};

        const themes = articleData?.themes?.map(
          ({ theme }) => theme && theme.document && theme.document.data
        );

        return {
          ...articleAdapter({ ...articleData, id, uid, type, url, themes }),
        };
      })
      .filter(item => !!item.uid),
  };
};

export const featuredArticleFigureListAdapter = ({ featuredArticle }) => {
  const article = featuredArticle;
  const articleData = article.document?.data || {};

  const { id, uid, type, url } = article.document || {};

  const themes = articleData?.themes?.map(
    ({ theme }) => theme && theme.document && theme.document.data
  );
  const { is_external_article } = articleData || {};

  return {
    featured: true,
    columns: 1,
    bgColor: colors.lightgrey,
    color: colors.black,
    showIcon: false,
    showWebsiteLabel: false,
    showMeta: false,
    hasTagInfos: !is_external_article,
    items: [articleAdapter({ ...articleData, id, uid, type, url, themes })],
  };
};

export const articlesCategoryFigureListAdapter = ({
  id,
  title,
  list,
  label,
  link,
  count = 3,
}) => {
  return {
    ...articlesFigureListAdapter({
      lastArticles: [],
      articlesPush: list,
      maxitems: count,
    }),
    id,
    title,
    link,
    linkLabel: label,
    columns: count,
    bgColor: colors.white,
    color: colors.black,
    hasTagInfos: true,
    showIcon: true,
    showMeta: false,
    hasSpacing: false,
    showDescription: true,
  };
};

export const splitFigureListAdapter = ({
  id,
  lastArticles,
  articlesPush,
  title,
  label,
  link,
}) => {
  return {
    ...articlesFigureListAdapter({
      lastArticles,
      articlesPush,
      maxitems: 5,
    }),
    id,
    title,
    link,
    linkLabel: label,
    bgColor: colors.white,
    color: colors.black,
    columns: 3,
    hasTagInfos: true,
    showIcon: true,
    showDescription: true,
    showMeta: false,
    showWebsiteLabel: false,
    hasSpacing: false,
    split: true,
  };
};
