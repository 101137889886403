import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import {
  colors,
  mediaquery,
  rem,
  scaleSpacing,
} from "../../../../styles/theme";
import Svg from "../../Atoms/Svg";
import {
  NotoBody,
  NotoLinkSmall,
  NotoLink,
  OswaldH3,
} from "../../Atoms/Typography";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;

  ${mediaquery.md(css`
    margin: 0 auto;
    justify-content: space-between;
    flex-direction: row;
  `)};
`;

const FirstPanel = styled.div`
  width: 100%;
  ${mediaquery.md(css`
    width: calc(var(--col) * 6 - var(--gutter-size));
  `)};
`;

const SecondPanel = styled.div`
  width: 100%;
  ${mediaquery.md(css`
    width: calc(var(--col) * 7 - var(--gutter-size));
  `)};
`;

const Title = styled.h2`
  ${OswaldH3};
  margin-bottom: ${scaleSpacing(4)};
`;

const SocialMedia = styled.div`
  width: 100%;
  height: ${rem(58)};
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.white};
  margin-bottom: ${scaleSpacing(4)};

  ${mediaquery.md(css`
    margin-bottom: 0;
  `)};
`;

const StyledImageLink = styled.a`
  ${NotoLink};
  color: ${colors.black};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
  margin-right: ${scaleSpacing(5)};
  text-transform: capitalize;
  &:last-of-type {
    margin-right: 0;
  }
  span {
    margin-right: ${scaleSpacing(2)};
  }
`;
const StyledInput = styled.div`
  ${NotoLinkSmall};
  background: ${colors.lightgrey};
  border-radius: ${rem(28)};
  color: ${colors.black};
  width: 100%;
  padding: ${scaleSpacing(4.5)} ${scaleSpacing(6)};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .link {
    text-decoration: underline;
    cursor: pointer;
  }

  .url {
    ${NotoBody};
    text-decoration: none;
    color: ${colors.mediumgrey2};
    white-space: nowrap;
    overflow: hidden;
    width: calc(var(--col) * 2);
    text-overflow: ellipsis;
    ${mediaquery.md(css`
      width: calc(var(--col) * 3);
    `)};
  }
`;

const ImageLink = ({ href, children }) => (
  <StyledImageLink href={href} target="_blank" rel="noopener">
    {children}
  </StyledImageLink>
);

ImageLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
};

const Share = ({ title, shareUrl, socialLinks }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <FirstPanel>
        {title && <Title>{title}</Title>}
        {!!socialLinks && (
          <SocialMedia>
            {socialLinks.map(
              ({ type, link }) =>
                type && (
                  <ImageLink key={type} href={link}>
                    <Svg
                      name={type}
                      size={24}
                      height={24}
                      fill={colors[type]}
                    />
                    {type}
                  </ImageLink>
                )
            )}
          </SocialMedia>
        )}
      </FirstPanel>

      <SecondPanel>
        <CopyToClipboard text={shareUrl}>
          <StyledInput>
            <p className="url">{shareUrl}</p>
            <p className="link">{t("curiosity_share_copytoclipboard")}</p>
          </StyledInput>
        </CopyToClipboard>
      </SecondPanel>
    </Container>
  );
};

Share.propTypes = {
  title: PropTypes.string.isRequired,
  socialLinks: PropTypes.array.isRequired,
  shareUrl: PropTypes.string.isRequired,
};

export default React.memo(Share);
