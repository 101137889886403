import { css } from "@emotion/core";

import { OswaldH5, NotoBodySmall } from "../src/components/Atoms/Typography";
import { colors, scaleSpacing } from "./theme";

const cookiebot = css`
  /* custom */
  #CybotCookiebotDialog {
    #CybotCookiebotDialogBody {
      max-width: calc(100% - var(--external-gutter));
    }

    #CybotCookiebotDialogBodyButtonAccept {
      background-color: ${colors.cinema};
      color: ${colors.white};
      border-radius: 30px;
      border: none;
      height: auto;
      padding: ${scaleSpacing(2)} ${scaleSpacing(8)};
    }
  }
  #CybotCookiebotDialogBodyContentTitle {
    ${OswaldH5};
  }
  #CybotCookiebotDialogBodyContentText {
    ${NotoBodySmall};
  }
`;

export default cookiebot;
