import { useStaticQuery, graphql } from "gatsby";

export default datamock => {
  const data = !datamock
    ? useStaticQuery(graphql`
        query FooterQuery {
          ...AllFooters
        }
      `)
    : datamock;

  if (!data && !data?.prismicFooter) {
    return null;
  }

  const website = process.env.GATSBY_WEBSITE;

  const footerData = data.allPrismicFooter?.edges?.filter(
    ({ node }) => node.data.site === website
  )[0].node.data;

  const {
    legals_mention_popin_details,
    links,
    socialtitle,
    sociallinks,
    cinemalink,
    curiositylink,
    institutlink,
    pluslink,
    troiscouleurslink,
    copyrightlabel,
    legalslabel: legalsLabel,
    legalslink: legalsLink,
  } = footerData || {};

  return {
    legalsMentionPopinDetails: legals_mention_popin_details?.raw,
    legalsLabel,
    legalsLink,
    copyrightLabel: copyrightlabel,
    mainLinks:
      links &&
      links.map(({ label, link, is_legals_mention }) => ({
        label,
        link,
        isLegalsMention: is_legals_mention,
      })),
    socialTitle: socialtitle,
    socialLinks:
      sociallinks &&
      sociallinks.map(({ type, link }) => ({
        type,
        link,
      })),
    cinemaLink: cinemalink,
    curiosityLink: curiositylink,
    institutLink: institutlink,
    plusLink: pluslink,
    troiscouleursLink: troiscouleurslink,
  };
};
