import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  getLogoTop,
  rem,
  mediaquery,
  scaleSpacing,
  zIndexes,
} from "../../../../styles/theme";
import useScrollPosition from "../../../hooks/useScrollPosition";
import Svg from "../../Atoms/Svg";
import Link from "../../Atoms/Link";
import Button from "../../Atoms/Button";
import CategoryNavigationLinks from "../../Molecules/CategoryNavigationLinks";
import useWindowSize from "../../../hooks/useWindowSize";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  padding: ${scaleSpacing(4)} 0;
  z-index: ${zIndexes.header};
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaquery.md(css`
    padding: ${scaleSpacing(6)} 0;
    justify-content: space-between;
    margin: 0;
  `)};

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  color: black;
  background-color: white;
  transition: all 300ms ease-out;
  ${({ show }) =>
    show
      ? css`
          visbility: visible;
        `
      : css`
          visbility: hidden;
          transition-timing-function: ease-in;
          transform: translate3d(0, -100%, 0);
        `}
`;

const BurgerSvg = styled(Svg)`
  cursor: pointer;
  position: absolute;
  left: ${scaleSpacing(6)};
  top: 50%;
  transform: translateY(-50%);

  ${mediaquery.md(css`
    left: ${scaleSpacing(6)};
  `)};

  ${mediaquery.l(css`
    left: ${scaleSpacing(10)};
  `)};
`;

const LogoLink = styled(Link)`
  display: inline-block;
  transform: scale(${({ shouldResize }) => (shouldResize ? 0.8 : 1)})
    translateY(${scaleSpacing(-0.6)});

  ${mediaquery.md(css`
    margin-left: ${scaleSpacing(18)};
  `)};

  ${mediaquery.l(css`
    margin-left: ${scaleSpacing(26)};
  `)};
`;

const LogoSvg = styled(Svg)`
  width: ${rem(151.2499999985)};
  height: ${rem(32)};
`;
const NavButton = styled(Button)`
  position: absolute;
  right: ${scaleSpacing(4)};
  ${mediaquery.md(css`
    display: none;
  `)};
`;

const Header = ({
  menu,
  theme,
  onClickBurger,
  onClickUser,
  onClickSearch,
  homeLink,
  showNewsletterIcon,
  showProfileIcon,
  showSearchIcon,
}) => {
  const [showOnScroll, setShowOnScroll] = useState(true);
  const { isMobile } = useWindowSize();

  // Handle header display animations
  useScrollPosition(({ prevPos, currPos }) => {
    const shouldChangeVisibility = Math.abs(currPos.y - prevPos.y) > 20;
    if (shouldChangeVisibility) {
      const show = -currPos.y < window.innerHeight || currPos.y > prevPos.y;
      setShowOnScroll(show);
    }
  });

  useEffect(() => {
    window?.scrollY === 0 && setShowOnScroll(true);
    window.prerenderReady = true;
  }, []);

  return (
    <Wrapper show={showOnScroll}>
      <BurgerSvg
        name="burger"
        size={24}
        height={18}
        fill="black"
        onClick={onClickBurger}
      />

      <LogoLink link={homeLink} shouldResize={menu.length > 3 && !isMobile}>
        <LogoSvg
          name={theme?.logo}
          top={getLogoTop(theme?.logoOffsetTop)}
          fill="black"
        />
      </LogoLink>

      {showSearchIcon && (
        <NavButton key="search" onClick={onClickSearch}>
          <Svg name="search" size={24} height={24} fill="black" />
        </NavButton>
      )}

      {menu && (
        <CategoryNavigationLinks
          menu={menu}
          onClickUser={onClickUser}
          onClickSearch={onClickSearch}
          color="black"
          showNewsletterIcon={showNewsletterIcon}
          showProfileIcon={showProfileIcon}
          showSearchIcon={showSearchIcon}
        />
      )}
    </Wrapper>
  );
};

Header.propTypes = {
  onClickBurger: PropTypes.func.isRequired,
  onClickUser: PropTypes.func.isRequired,
  onClickSearch: PropTypes.func.isRequired,
  homeLink: PropTypes.object,
  menu: PropTypes.array,
  theme: PropTypes.object,
  showNewsletterIcon: PropTypes.bool,
  showProfileIcon: PropTypes.bool,
  showSearchIcon: PropTypes.bool,
};

Header.defaultProps = {
  onClickBurger: () => {},
  onClickUser: () => {},
  onClickSearch: () => {},
  onCloseModal: () => {},
};

export default React.memo(Header);
