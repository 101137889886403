import React from "react";

export default function useOnScroll(onScroll) {
  const r = React.useRef(onScroll);

  React.useEffect(() => {
    r.current = onScroll;
  }, [onScroll]);

  React.useEffect(() => {
    let af;
    const fn = () => {
      cancelAnimationFrame(af);
      af = requestAnimationFrame(r.current);
    };
    window.addEventListener("scroll", fn);
    return () => {
      window.removeEventListener("scroll", fn);
    };
  }, []);
}
