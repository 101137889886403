import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import React from "react";
import { useTheme } from "emotion-theming";
import { connectMenu } from "react-instantsearch-dom";
import { colors, scaleSpacing } from "../../../../styles/theme";

import IconSvg from "../../Atoms/Svg";
import { NotoBody } from "../../Atoms/Typography";

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  margin: 0 var(--external-gutter);
  justify-content: center;
  margin-bottom: ${scaleSpacing(8)};
`;

const StyledSelect = styled.select`
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 ${colors.black};
  }

  &:focus {
    border-color: ${colors.darkgrey2};
  }

  width: 100%;
  position: relative;
  height: ${scaleSpacing(12)};
  padding: 0 ${scaleSpacing(6)};
  margin: 0;
  border-radius: ${scaleSpacing(7)};
  border: none;
  outline: none;
  ${NotoBody};
  background: ${colors.darkgrey3};
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
`;

const Svg = styled(IconSvg)`
  position: absolute;
  right: ${scaleSpacing(5)};
  top: 0;
  bottom: 0;
  margin: auto;
  pointer-events: none;
`;

const MenuSelect = ({ items, currentRefinement, refine }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <SelectWrapper>
      <StyledSelect
        value={currentRefinement || ""}
        onChange={event => refine(event.currentTarget.value)}
        bgColor={theme.filterBackgroundColor}
        color={theme.filterColor}
      >
        <option value="">{t(`search_category_label_all`)}</option>
        {items.map(item => (
          <option
            key={item.label}
            value={item.isRefined ? currentRefinement : item.value}
          >
            {t(`search_category_label_${item.label}`)} ({item.count})
          </option>
        ))}
      </StyledSelect>
      <Svg name="filter" size={20} height={20} fill={theme.filterColor} />
    </SelectWrapper>
  );
};

MenuSelect.propTypes = {
  items: PropTypes.array.isRequired,
  currentRefinement: PropTypes.array,
  createURL: PropTypes.func,
  refine: PropTypes.func,
};

const CustomMenuSelect = connectMenu(MenuSelect);

export default CustomMenuSelect;
