import qs from "qs";

export const createURL = (state, prefix = "search") =>
  `/${prefix ? prefix : ""}?${qs.stringify(state)}`;

export const urlToSearchState = ({ search }) => qs.parse(search.slice(1));

export const searchStateToUrl = ({ location }, searchState) => {
  return searchState ? `${location?.origin}${createURL(searchState)}` : "";
};
