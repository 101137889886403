import React from "react";
import PropTypes from "prop-types";

import { colors, getArticleIcon } from "../../../../styles/theme";
import IconSvg from "../../Atoms/Svg";
import SoundIcon from "../../Atoms/SoundIcon";
import EyeIcon from "../../Atoms/EyeIcon";

function ArticleIcon({ format, color }) {
  const iconLabel = getArticleIcon(format);
  switch (iconLabel) {
    case "":
      return null;
    case "podcast":
      return <SoundIcon active color={color} />;
    case "article":
      return <EyeIcon active color={color} />;
    default:
      return <IconSvg size={16} name={iconLabel} fill={color} />;
  }
}

ArticleIcon.propTypes = {
  format: PropTypes.string.isRequired,
  color: PropTypes.string,
};

ArticleIcon.defaultProps = {
  color: colors.white,
};

export default ArticleIcon;
